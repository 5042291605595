import auth from "@/middleware/auth.js";

const parseUrlParamToInt = (route, param) => {
    let value = Number.parseInt(route.params[param], 10);
    if (Number.isNaN(value)) {
        value = null;
    }

    return value;
}

export default [
    {
        path: "",
        name: "SiteAdminUsers",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/Users.vue"),
        meta: {
            middleware: auth
        }
    },
    {
        path: "demo-users",
        name: "SiteAdminDemoUsers",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/DemoUsers.vue"),
        meta: {
            middleware: auth
        }
    },
    {
        path: "new-user",
        name: "SiteAdminNewUser",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/NewUser.vue"),
        meta: {
            middleware: auth
        }
    },
    {
        path: "edit-user/:userId",
        name: "SiteAdminEditUser",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/EditUser.vue"),
        meta: {
            middleware: auth
        },
        props: route => {
            let userId = parseUrlParamToInt(route, 'userId');

            return { userId };
        }
    },
    {
        path: "licences/:userId",
        name: "SiteAdminUserLicences",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/Licences.vue"),
        meta: {
            middleware: auth
        },
        props: route => {
            let userId = parseUrlParamToInt(route, 'userId');

            return { userId };
        }
    },
    {
        path: "info/:userId",
        name: "SiteAdminUserInfo",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/Info.vue"),
        meta: {
            middleware: auth
        },
        props: route => {
            let userId = parseUrlParamToInt(route, 'userId');
            
            return { userId };
        }
    }
];
