<template>
  <div class="flex flex-row flex-no-wrap justify-between items-center mb-0 print:hidden">
    <div class="flex-none w-1/3 md:w-1/3 logo-wrapper">
      <opus-logo
        @click.prevent="goHome()"
        class="w-full xl:w-2/3 px-4 md:px-12 py-4 xl:py-8" />
    </div>
    <div class="flex-none w-0 md:w-1/3 text-center pr-4 md:pr-0 questions-wrapper"></div>
    <div class="flex-none w-2/3 md:w-1/3 text-right px-4 md:pr-12 py-4 md:py-8 contact-wrapper flex justify-end">
      <div class="md:mr-4 sm:mr-0">
        <p class="text-dark-grey text-us xs:text-xs md:text-sm lg:text-base xl:text-xl">
          <span class="hidden xs:inline">Need Help?&nbsp;</span>Contact our friendly support team on
        </p>
        <p class="font-bold text-vs xs:text-xs md:text-sm lg:text-2xl">
          <a
            v-if="hasGroupAdmin || hasSubgroupAdmin"
            href="tel:+443339390053"
            class="text-primary">
            0333 939 0053 (option 2)</a
          >
          <a
          v-else
            href="mailto:support@opuspharmserve.com"
            class="text-primary"
            >support@opuspharmserve.com</a
          >
        </p>
        <p class="text-us xs:text-xs md:text-sm lg:text-base xl:text-lg">
          <span class="text-dark-grey">or click the orange ? button</span>
        </p>
      </div>
      <button
        @click.prevent="$emit('openHelp')"
        class="text-primary ml-2 md:ml-0"
        title="Help">
        <IconQuestionCircle class="w-4 h-4 md:w-16 md:h-16" />
      </button>
      <button
        @click.prevent="$store.commit('toggleMobileMenu')"
        class="text-black block md:hidden ml-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import OpusLogo from "@/components/OpusLogo.vue";

export default {
  name: "SiteHeader",
  components: {
    OpusLogo
  },
  computed: {
    showLearningZone() {
      return (
        this.$route && this.$route.name && this.$route.name !== "Login" && this.$route.name !== "ForgottenPassword"
      );
    }
  },
  methods: {
    goHome() {
      if (this.hasSuperAdmin) {
        return this.$router.push({ name: "SiteAdmin" });
      }

      if (this.hasGroupAdmin) {
        return this.$router.push({ name: "GroupAdmin" });
      }

      if (this.hasSubgroupAdmin) {
        return this.$router.push({ name: "SubgroupAdmin" });
      }

      if (this.hasTrainerRole) {
        return this.$router.push({ name: "VirtualGroupAdmin" });
      }

      this.$router.push({ name: "MyCourses" });
    }
  }
};
</script>

<style lang="postcss">
.logo-wrapper {
  order: 1;
}
.contact-wrapper {
  order: 2;
}
.questions-wrapper {
  order: 3;
  @apply hidden;
}

.header-zone-logo-box,
.header-zone-text-box,
.header-zone-badge-box {
  display: none;
}

@screen md {
  .contact-wrapper {
    order: 3;
  }
  .questions-wrapper {
    order: 2;
    @apply block;
  }
}

@screen lg {
  .header-zone-text-box {
    display: block;
  }
}

@screen xl {
  .header-zone-logo-box,
  .header-zone-text-box,
  .header-zone-badge-box {
    display: block;
  }
}
</style>
