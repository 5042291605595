import auth from "@/middleware/auth.js";
import atOrgLevel from "@/middleware/atOrgLevel.js";
import groupSubgroupChildren from "@/router/site_admin/groupSubgroupChildren";
import groupVirtualGroupChildren from "@/router/site_admin/groupVirtualGroupChildren";
import store from "@/store";
const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminGroups",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/Groups.vue"),
    meta: {
      middleware: [auth, atOrgLevel]
    },
  },
  {
    path: "archived",
    name: "SiteAdminArchivedGroups",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/ArchivedGroups.vue"),
    meta: {
      middleware: [auth, atOrgLevel]
    },
  },
  {
    path: "new-group",
    name: "SiteAdminGroupNew",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/GroupNew.vue"),
    meta: {
      middleware: [auth, atOrgLevel]
    }
  },
  {
    path: ":groupId",
    name: "SiteAdminGroupGroupLayout",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/GroupLayout.vue"),
    meta: {
      middelware: auth
    },
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");

      return {
        groupId
      };
    },
    beforeEnter: (to, from, next) => {
      store
        .dispatch("admin/primeCurrentGroup", {
          groupId: parseInt(to.params.groupId, 10)
        })
        .then(() => {
          next();
        })
        .catch(() => {
          next();
        });
    },
    children: [
      {
        path: "dashboard",
        name: "SiteAdminGroupDashboard",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/GroupDashboard.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "merge-members",
        name: "SiteAdminMergeMembers",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MergeMembers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "edit-group",
        name: "SiteAdminGroupEdit",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/GroupEdit.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "new-subgroup",
        name: "SiteAdminGroupSubgroupNew",
        component: () =>
          import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/subgroups/SubgroupNew.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "new-virtual-group",
        name: "SiteAdminGroupVirtualGroupNew",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/groups/virtual_groups/VirtualGroupNew.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "edit-user/:userId",
        name: "SiteAdminGroupEditUser",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/EditUser.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");
          let groupingLevel = "group";
          let apiAuthLevel = "superAdmin";

          return {
            userId,
            groupingLevel,
            apiAuthLevel,
            groupId
          };
        }
      },
      {
        path: "members",
        name: "SiteAdminGroupMembers",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/GroupingMembers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let groupingLevel = "group";
          let apiAuthLevel = "superAdmin";
          let editUserRoute = {
            name: "SiteAdminGroupEditUser",
            params: {
              userId: null
            }
          };
          let userInfoRoute = {
            name: "SiteAdminGroupMembersInfo",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          let licencesRoute = {
            name: "SiteAdminGroupMembersLicences",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          return {
            groupingLevel,
            groupId,
            apiAuthLevel,
            editUserRoute,
            userInfoRoute,
            licencesRoute
          };
        }
      },
      {
        path: "archived-members",
        name: "SiteAdminArchivedGroupMembers",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/ArchivedGroupMembers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "licences",
        name: "SiteAdminGroupLicences",
        component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/licences/Licences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let adminLevel = "superAdmin";

          return {
            groupId,
            adminLevel
          };
        }
      },
      {
        path: "licences/:abstractCourseId/:certificateMonths",
        name: "SiteAdminGroupLicencesForCourse",
        component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/licences/LicencesForCourse.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractCourseId,
            certificateMonths
          };
        }
      },
      {
        path: "licences/paper-course/:abstractPaperCourseId/:certificateMonths",
        name: "SiteAdminGroupLicencesForPaperCourse",
        component: () =>
          import(/* webpackChunkName: "commonAdmin" */ "@/views/common/licences/LicencesForPaperCourse.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractPaperCourseId = parseUrlParamToInt(route, "abstractPaperCourseId");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractPaperCourseId,
            certificateMonths
          };
        }
      },
      {
        path: "licences/:abstractCourseId/bulk-allocation-from-general-pot/:available/:certificateMonths",
        name: "SiteAdminGroupBulkAllocateGroupLicenceToMembers",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/licences/BulkIssueLicencesFromPot.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let available = parseUrlParamToInt(route, "available");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractCourseId,
            available,
            certificateMonths
          };
        }
      },
      {
        path: "licences/:abstractCourseId/pool-allocation/:available/:certificateMonths",
        name: "SiteAdminGroupAllocateGroupLicenceToPool",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/licences/AllocateGroupLicenceToPool.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let available = parseUrlParamToInt(route, "available");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractCourseId,
            available,
            certificateMonths
          };
        }
      },
      {
        path: "new-licence",
        name: "SiteAdminGroupsNewLicence",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/NewLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "new-licence-for-subgroup/:subgroupId",
        name: "SiteAdminSubgroupsNewLicence",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/NewLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "edit-cert-months",
        name: "SiteAdminGroupsEditCertMonths",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/EditCertMonths.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "new-licence-for-trainer-led-course",
        name: "SiteAdminGroupsNewLicenceForTrainerLedCourse",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/common/groups/NewLicenceForTrainerLedCourse.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "members-licences/:userId/subgroup/:subgroupId",
        name: "SiteAdminGroupSubgroupMembersLicences",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MembersLicences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            subgroupId,
            userId
          };
        }
      },
      {
        path: "members-licences/:userId/virtual-group/:virtualGroupId",
        name: "SiteAdminGroupVirtualGroupMembersLicences",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MembersLicences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            virtualGroupId,
            userId
          };
        }
      },
      {
        path: "members-info/:userId",
        name: "SiteAdminGroupMembersInfo",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MembersInfo.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId
          };
        }
      },
      {
        path: "members-licences/:userId",
        name: "SiteAdminGroupMembersLicences",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MembersLicences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId
          };
        }
      },
      {
        path: "issue-licence/:userId/subgroup/:subgroupId",
        name: "SiteAdminGroupSubgroupIssueLicence",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/IssueLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            subgroupId,
            userId
          };
        }
      },
      {
        path: "issue-licence/:userId/virtual-group/:virtualGroupId",
        name: "SiteAdminGroupVirtualGroupIssueLicence",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/IssueLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            virtualGroupId,
            userId
          };
        }
      },
      {
        path: "issue-licence/:userId",
        name: "SiteAdminGroupIssueLicence",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/IssueLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId
          };
        }
      },
      {
        path: "subgroups",
        name: "SiteAdminGroupSubgroupsLayout",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/subgroups/Layout.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        },
        children: [...groupSubgroupChildren]
      },
      {
        path: "archived-subgroups",
        name: "SiteAdminGroupArchivedSubgroups",
        component: () =>
          import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/subgroups/ArchivedSubgroups.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },

      {
        path: "virtual-groups",
        name: "SiteAdminGroupVirtualGroupsLayout",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/groups/virtual_groups/Layout.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        },
        children: [...groupVirtualGroupChildren]
      },

      {
        path: "historic-virtual-groups",
        name: "SiteAdminGroupHistoricVirtualGroups",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/groups/virtual_groups/HistoricVirtualGroups.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let infoRouteName = "SiteAdminGroupHistoricVirtualGroupInfo";

          return {
            groupId,
            infoRouteName
          };
        }
      },

      {
        path: "historic-virtual-group/info/:virtualGroupId",
        name: "SiteAdminGroupHistoricVirtualGroupInfo",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/common/groups/virtual_groups/HistoricVirtualGroupInfo.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            virtualGroupId
          };
        }
      },

      {
        path: "add-existing-user",
        name: "SiteAdminGroupAddExistingUser",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/AddExistingUser.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "new-user",
        name: "SiteAdminGroupNewUser",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/CreateNewUser.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "bulk-upload-new-users",
        name: "SiteAdminGroupBulkNewUsers",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/BulkUploadNewUsers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "bulk-upload-edit-users",
        name: "SiteAdminGroupBulkEditUsers",
        component: () =>
          import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/BulkUploadEditUsers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "bulk-password-reset",
        name: "SiteAdminGroupBulkPasswordReset",
        component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/groups/BulkPasswordReset.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "settings",
        name: "SiteAdminGroupSettings",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/site_admin/groups/Settings.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "certificate-settings/online-courses/:abstractCourseId",
        name: "SiteAdminGroupCourseCertificateSettings",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/licences/AbstractCourseCertificateSettings.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");

          return {
            groupId,
            abstractCourseId
          };
        }
      },
      {
        path: "certificate-settings/paper-courses/:abstractPaperCourseId",
        name: "SiteAdminGroupPaperCourseCertificateSettings",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/groups/PaperCourseCertificateSettings.vue"
          ),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractPaperCourseId = parseUrlParamToInt(route, "abstractPaperCourseId");

          return {
            groupId,
            abstractPaperCourseId
          };
        }
      }
    ]
  }
];
