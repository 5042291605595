<template>
    <div class="w-full h-full">
        <iframe :src="helpUrl" class="w-full h-full"></iframe>
    </div>
</template>

<script>
export default {
    name: "SiteHelp",
    computed: {
        helpUrl() {
            if (
                [
                    "MyCourses",
                    "Account",
                    "Content",
                    "Login",
                    "ForgottenPassword",
                    "TermsAndConditionsPage",
                    "PrivacyPolicy"
                ].findIndex(r => r === this.$route.name) !== -1
            ) {
                return "/help/";
            }

            if (this.hasSuperAdmin) {
                return "/super-admin-help/index.html";
            }

            if (this.hasGroupAdmin) {
                return "/org-admin-help/index.html";
            }

            if (this.hasSubgroupAdmin) {
                let subgroupId = null;

                if(this.$store.state.admin.currentSubgroup && this.$store.state.admin.currentSubgroup.id) {
                    subgroupId = this.$store.state.admin.currentSubgroup.id;
                }

                if(this.subgroupAdminLevel(subgroupId) === 'LOW') {
                    return "/service-level3-admin-help/index.html";
                } else {
                    return "/service-admin-help/index.html";
                }
            }

            if (this.hasTrainerRole) {
                return "/trainer-help/index.html";
            }

            return "/help/";
        }
    }
};
</script>
