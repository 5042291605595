<template>
  <div>
    <transition name="fade">
      <div
        class="popup-modal"
        :class="{ large: largeWindow }"
        v-if="isVisible">
        <div :class="[largeWindow ? 'large-window' : 'window']">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PopupModal",

  props: {
    largeWindow: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isVisible: false
  }),

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped lang="postcss">
.popup-modal {
  @apply bg-black bg-opacity-80 fixed inset-0 flex flex-row justify-center items-center z-50;
}

.popup-modal.large {
  @apply p-4;
  @apply flex-col justify-between;
}

.window {
  @apply bg-white rounded-md shadow-lg w-5/6 lg:w-1/2 p-2 lg:p-20;
}

.large-window {
  @apply bg-secondary rounded-md shadow-lg w-full h-full p-2 md:p-10 flex flex-col justify-between;
}
</style>
