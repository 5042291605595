import auth from "@/middleware/auth.js";

const parseUrlParamToInt = (route, param) => {
    let value = Number.parseInt(route.params[param], 10);
    if (Number.isNaN(value)) {
        value = null;
    }

    return value;
}

export default [{
        path: "",
        name: "GroupAdminGroupVirtualGroups",
        component: () =>
            import(
                /* webpackChunkName: "groupAdmin" */
                "@/views/group_admin/groups/virtual_groups/VirtualGroups.vue"
            ),
        meta: {
            middleware: auth
        },
        props: route => {
            let groupId = parseUrlParamToInt(route, 'groupId');

            return {
                groupId
            };
        }
    },
    {
        path: ":virtualGroupId",
        name: "GroupAdminGroupVirtualGroupLayout",
        component: () =>
            import(
                /* webpackChunkName: "groupAdmin" */
                "@/views/group_admin/groups/virtual_groups/VirtualGroupLayout.vue"
            ),
        meta: {
            middleware: auth
        },
        props: route => {
            let groupId = parseUrlParamToInt(route, 'groupId');
            let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');

            return {
                groupId,
                virtualGroupId
            };
        },
        children: [{
                path: "edit",
                name: "GroupAdminGroupVirtualGroupEdit",
                component: () =>
                    import(
                        /* webpackChunkName: "groupAdmin" */
                        "@/views/group_admin/groups/virtual_groups/VirtualGroupEdit.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');
                    let adminLevel = "mixedAdmin";
                    return {
                        groupId,
                        virtualGroupId,
                        adminLevel
                    };
                }
            },
            {
                path: "members",
                name: "GroupAdminGroupVirtualGroupMembers",
                component: () =>
                    import(
                        /* webpackChunkName: "groupAdmin" */
                        "@/views/common/GroupingMembers.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');
                    let groupingLevel = "virtual group";
                    let apiAuthLevel = "groupingAdmin";
                    let editUserRoute = {
                        name: "GroupAdminGroupEditUser",
                        params: {
                            userId: null
                        }
                    };
                    let userInfoRoute = {
                        name: "GroupAdminGroupVirtualGroupMembersInfo",
                        params: {
                            virtualGroupId: virtualGroupId,
                            userId: null
                        }
                    };
                    let licencesRoute = {
                        name: "GroupAdminGroupVirtualGroupMembersLicences",
                        params: {
                            virtualGroupId: virtualGroupId,
                            userId: null
                        }
                    };
                    return {
                        groupingLevel,
                        groupId,
                        virtualGroupId,
                        apiAuthLevel,
                        editUserRoute,
                        userInfoRoute,
                        licencesRoute
                    };
                }
            },
            {
                path: "membership",
                name: "GroupAdminGroupVirtualGroupMembership",
                component: () =>
                    import(
                        /* webpackChunkName: "groupAdmin" */
                        "@/views/group_admin/groups/virtual_groups/VirtualGroupMembership.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');
                    
                    return {
                        groupId,
                        virtualGroupId
                    };
                }
          },
          {
            path: "bulk-upload-new-users",
            name: "GroupAdminVirtualGroupBulkNewUsers",
            component: () =>
              import(
                /* webpackChunkName: "siteAdmin" */
                "@/views/common/groups/virtual_groups/BulkUploadNewUsers.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
    
              return {
                groupId,
                virtualGroupId
              };
            }
          }
        ]
    }
];
