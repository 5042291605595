<script setup>
import SideNav from "@/views/personal/my_courses/partials/SideNav.vue";
import store from "@/store";

const emit = defineEmits(["openHelp"]);
</script>
<template>
  <div class="flex flex-row flex-nowrap min-h-screen px-0 relative frontend">
    <SideNav />
    <div
      id="main-content"
      class="relative w-full"
      :class="{ expanded: store.state.menuExpanded }">
      <router-view v-slot="{ Component }">
        <transition
          name="fade"
          mode="out-in">
          <component
            @openHelp="emit('openHelp')"
            :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>
<style scoped>
.frontend * {
  font-family: "Open Sans", sans-serif;
}

#main-content {
  margin-left: 0;
}

@media screen and (min-width: 450px) {
  /* let the menu sit over the content */
}

@media screen and (min-width: 768px) {
  #main-content {
    margin-left: 80px;
  }

  #main-content.expanded {
    margin-left: 352px;
  }
}
</style>
