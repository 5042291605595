<script setup>
import { toRef } from "vue";

const props = defineProps({
  quantity: Number,
  singular: String,
  plural: String
});

const quantityRef = toRef(props, "quantity");
</script>
<template>
  <span v-if="quantityRef === 0 || quantityRef > 1">
    {{ plural }}
  </span>
  <span v-else>
    {{ singular }}
  </span>
</template>
