<template>
    <footer class="site-footer print:hidden text-xs md:text-base">
        <router-link :to="{ name: 'PrivacyPolicy' }" class="mx-4 hover:text-primary duration-150">Privacy Policy
        </router-link>|
        <router-link :to="{ name: 'TermsAndConditionsPage' }" class="mx-4 hover:text-primary duration-150">Terms and
            Conditions</router-link>
        <template v-if="showLogin">|
            <router-link :to="{ name: 'Login' }" class="mx-4 hover:text-primary duration-150">Log In</router-link>
        </template>
        <template v-if="showLogout">|
            <router-link :to="{ name: 'Logout' }" class="mx-4 hover:text-primary duration-150">Log Out</router-link>
        </template>
    </footer>
</template>
<script>
export default {
    name: "SiteFooter",
    computed: {
        showLogin() {
            return !this.$store.state.user;
        },
        showLogout() {
            return this.$store.state.user;
        }
    }
}
</script>