<template>
    <div class="p-10 fixed inset-0 bg-black bg-opacity-80 flex flex-row justify-center items-center z-60">
        <close-button @clicked="cancelQuestion" />
        <transition name="fade" mode="out-in">
            <spinner-message v-if="saving" display-type="centered" message="Saving..." />
            <div
                v-else
                class="w-full xl:w-3/4 bg-white h-full p-16 rounded-lg shadow-2xl overflow-y-auto z-70"
                @click.stop=""
            >
                <div class="flex flex-row justify-start items-end mb-8">
                    <div class="w-1/5">
                        <opus-logo class="w-full" />
                    </div>
                    <div class="w-4/5 pl-10">
                        <h1 class="leading-none text-secondary font-bold">
                            Ask the Pharmacist
                        </h1>
                    </div>
                </div>

                <v-form @submit="save" :validation-schema="schema">
                    <label class="control-label mt-8 mb-2" for="name">Name</label>
                    <v-field
                        type="text"
                        maxlength="255"
                        name="name"
                        id="name"
                        v-model="payload.name"
                        class="form-control"
                    />
                    <div class="error-message">
                        <error-message name="name" />
                    </div>

                    <label class="control-label mt-8 mb-2" for="question">Question</label>
                    <v-field
                        as="textarea"
                        type="textarea"
                        maxlength="512"
                        name="question"
                        id="question"
                        v-model="payload.question"
                        class="form-control h-64"
                    />
                    <div class="error-message">
                        <error-message name="question" />
                    </div>

                    <div class="mt-4 flex justify-between">
                        <button class="btn bg-gray-200 mr-8" @click.prevent="$emit('cancel')">Cancel</button>
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </v-form>
            </div>
        </transition>
    </div>
</template>

<script>
import OpusLogo from "@/components/OpusLogo.vue";
import { Form } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/services/ApiService";
import CloseButton from "@/components/utilities/CloseButton.vue";

export default {
    name: "AskThePharmacist",
    components: {
        OpusLogo,
        VForm: Form,
        VField: Field,
        ErrorMessage,
        CloseButton
    },
    data() {
        return {
            payload: {
                name: "",
                question: ""
            },
            schema: {
                name: value => {
                    if (value && value.length > 255) {
                        return "Please keep your name shorter than 255 characters.";
                    }

                    if (value && value.length) {
                        return true;
                    }

                    return "Please enter your name";
                },
                question: value => {
                    if (value && value.length > 512) {
                        return "Please keep your question shorter than 512 characters.";
                    }

                    if (value && value.length) {
                        return true;
                    }

                    return "Please enter your question";
                }
            },
            saving: false
        };
    },
    mounted() {
        if (this.$store.state.user && this.$store.state.user.first_name) {
            this.payload.name = this.$store.state.user.first_name + " " + this.$store.state.user.last_name;
        }
    },
    methods: {
        save() {
            this.saving = true;
            ApiService.postAskThePharmacist(this.payload)
                .then(() => {
                    this.flashSuccess(
                        "Thank you for your question, a member of the OPUS team will get back to you shortly.",
                        10000
                    );
                    this.$emit("done");
                    this.saving = false;
                })
                .catch(() => {
                    this.$emit("cancel");
                    this.saving = false;
                });
        },
        cancelQuestion() {
            if (this.saving) {
                //can't cancel during save
                return;
            }

            this.$emit("cancel");
        }
    }
};
</script>

<style lang="postcss" scoped>
.error-message {
    @apply mt-2 text-red-500;
}
</style>
