<template>
    <label v-if="label" :for="uniqueName" class="control-label">{{ label }}</label>
    <Field
        :id="uniqueName"
        :name="uniqueName"
        :rules="rules"
        :value="modelValue"
        class="form-control"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
    />
    <ErrorMessage :name="uniqueName" class="form-control-error" />
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
    name: "BaseInput",
    inheritAttrs: false,
    components: {
        Field,
        ErrorMessage
    },
    props: {
        uniqueName: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ""
        },
        modelValue: {
            type: [String, Number],
            default: ""
        },
        rules: {
            type: String,
            default: "required"
        }
  }
};
</script>
