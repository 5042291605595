<template>
  <div>
    <label
      class="control-label"
      :for="uid"
      >{{ label }}</label
    >
    <div class="flex items-center gap-4">
      <button
        class="btn btn-primary btn-sm"
        type="button"
        @click="$refs.fileInput.click()">
        <template v-if="!selectedFileName">Select File</template><span v-else>Change Selection</span>
      </button>
      <span v-if="selectedFileName"
        >Currently Selected: <strong>{{ selectedFileName }}</strong></span
      >
    </div>

    <input
      class="hidden"
      ref="fileInput"
      :id="uid"
      type="file"
      @change="handleFileChange" />
  </div>
</template>

<script>
export default {
  name: "BaseFileUploader",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    value: [String, Number, Blob]
  },
  data() {
    return {
      uid: crypto.randomUUID(),
      selectedFileName: ""
    };
  },
  methods: {
    handleFileChange($event) {
      this.selectedFileName = $event.target.files[0].name;
      this.$emit("update:modelValue", $event.target.files[0]);
    }
  }
};
</script>
