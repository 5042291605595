import auth from "@/middleware/auth.js";
import atOrgLevel from "@/middleware/atOrgLevel.js";

const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "GroupAdminGroupSubgroups",
    component: () =>
      import(
        /* webpackChunkName: "groupAdmin" */
        "@/views/group_admin/groups/subgroups/Subgroups.vue"
      ),
    meta: {
      middleware: [auth, atOrgLevel]
    },
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");

      return {
        groupId
      };
    }
  },
  {
    path: ":subgroupId",
    name: "GroupAdminGroupSubgroupLayout",
    component: () =>
      import(
        /* webpackChunkName: "groupAdmin" */
        "@/views/group_admin/groups/subgroups/SubgroupLayout.vue"
      ),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");
      let subgroupId = parseUrlParamToInt(route, "subgroupId");

      return {
        groupId,
        subgroupId
      };
    },
    children: [
      {
        path: "edit",
        name: "GroupAdminGroupSubgroupEdit",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/SubgroupEdit.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "new-user",
        name: "GroupAdminSubgroupNewUser",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/CreateNewUser.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "bulk-upload-new-users",
        name: "GroupAdminSubgroupBulkNewUsers",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/BulkUploadNewUsers.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "members",
        name: "GroupAdminGroupSubgroupMembers",
        component: () => import(/* webpackChunkName: "groupAdmin" */ "@/views/common/GroupingMembers.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          let groupingLevel = "subgroup";
          let apiAuthLevel = "groupingAdmin";
          let editUserRoute = {
            name: "GroupAdminGroupSubgroupEditUser",
            params: {
              userId: null
            }
          };
          let userInfoRoute = {
            name: "GroupAdminGroupSubgroupMembersInfo",
            params: {
              subgroupId: subgroupId,
              userId: null
            }
          };
          let licencesRoute = {
            name: "GroupAdminGroupSubgroupMembersLicences",
            params: {
              subgroupId: subgroupId,
              userId: null
            }
          };
          return {
            groupingLevel,
            groupId,
            subgroupId,
            apiAuthLevel,
            editUserRoute,
            userInfoRoute,
            licencesRoute
          };
        }
      },
      {
        path: "edit-user/:userId",
        name: "GroupAdminGroupSubgroupEditUser",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/EditUser.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");
          let groupingLevel = "subgroup";
          let apiAuthLevel = "groupingAdmin";

          return {
            userId,
            apiAuthLevel,
            groupingLevel,
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "membership",
        name: "GroupAdminGroupSubgroupMembership",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/SubgroupMembership.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "licences",
        name: "GroupAdminGroupSubgroupLicences",
        component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/licences/SubgroupLicences.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "licences/bulk-issue-from-general-pot/:abstractCourseId/:available/:certificateMonths",
        name: "GroupAdminBulkAllocateGroupSubgroupLicenceToMembersFromPot",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/licences/SubgroupBulkIssueLicences.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let available = parseUrlParamToInt(route, "available");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            subgroupId,
            abstractCourseId,
            available,
            source: "pot",
            certificateMonths
          };
        }
      },
      {
        path: "licences/bulk-issue-from-pool/:abstractCourseId/:available/:certificateMonths",
        name: "GroupAdminBulkAllocateGroupSubgroupLicenceToMembersFromPool",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/licences/SubgroupBulkIssueLicences.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let available = parseUrlParamToInt(route, "available");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            subgroupId,
            abstractCourseId,
            available,
            source: "pool",
            certificateMonths
          };
        }
      },
      {
        path: "virtual-groups",
        name: "GroupAdminGroupSubgroupVirtualGroups",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroups.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");

          return {
            groupId,
            subgroupId
          };
        }
      },
      {
        path: "historic-virtual-groups",
        name: "GroupAdminGroupSubgroupHistoricVirtualGroups",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/groups/subgroups/virtual_groups/SubgroupHistoricVirtualGroups.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let infoRouteName = "GroupAdminGroupSubgroupHistoricVirtualGroupInfo";

          return {
            groupId,
            subgroupId,
            infoRouteName
          };
        }
      },

      //Note we're using the same view that's used by group level
      {
        path: "historic-virtual-group/info/:virtualGroupId",
        name: "GroupAdminGroupSubgroupHistoricVirtualGroupInfo",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/common/groups/virtual_groups/HistoricVirtualGroupInfo.vue"
          ),
        meta: {
          middleware: [auth]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            subgroupId,
            virtualGroupId
          };
        }
      },
      {
        path: "virtual-group/:virtualGroupId/edit",
        name: "GroupAdminGroupSubgroupVirtualGroupEdit",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroupEdit.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            subgroupId,
            virtualGroupId
          };
        }
      },
      {
        path: "virtual-group/:virtualGroupId/members",
        name: "GroupAdminGroupSubgroupVirtualGroupMembers",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/GroupingMembers.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let groupingLevel = "virtual group";
          let apiAuthLevel = "groupingAdmin";
          let editUserRoute = {
            name: "GroupAdminGroupSubgroupEditUser",
            params: {
              userId: null
            }
          };
          let userInfoRoute = {
            name: "GroupAdminGroupSubgroupVirtualGroupMembersInfo",
            params: {
              virtualGroupId: virtualGroupId,
              userId: null
            }
          };
          let licencesRoute = {
            name: "GroupAdminGroupMembersLicences",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          return {
            groupingLevel,
            groupId,
            subgroupId,
            virtualGroupId,
            apiAuthLevel,
            editUserRoute,
            userInfoRoute,
            licencesRoute
          };
        }
      },
      {
        path: "virtual-group/:virtualGroupId/membership",
        name: "GroupAdminGroupSubgroupVirtualGroupMembership",
        component: () =>
          import(
            /* webpackChunkName: "groupAdmin" */
            "@/views/group_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroupMembership.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            subgroupId,
            virtualGroupId
          };
        }
      },
      {
        path: "virtual-groups/:virtualGroupId/members-info/:userId",
        name: "GroupAdminGroupSubgroupVirtualGroupMembersInfo",
        component: () => import(/* webpackChunkName: "groupAdmin" */ "@/views/group_admin/groups/MembersInfo.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId,
            subgroupId
          };
        }
      }
    ]
  }
];
