<script setup>
import OpusLogo from "@/components/OpusLogo.vue";
const emit = defineEmits(["openHelp"]);
</script>
<template>
<div class="grid grid-cols-3">
  <div class="pl-4 sm:pl-20 flex justify-start items-center h-16 sm:h-36 col-span-1">
      <div class="w-32 sm:w-56">
        <opus-logo @click="$router.push({name: 'Login'})" />
      </div>
    </div>
    <div class="pr-4 sm:pr-20 flex justify-end items-center h-16 sm:h-36 col-span-2">
      <span class="text-xs sm:text-lg lg:text-xl mr-1 sm:mr-4 font-light">Click for technical support </span
      ><button
        @click.prevent="emit('openHelp')"
        class="text-primary"
        title="Help">
        <IconQuestionCircle class="w-5 h-5 md:w-20 md:h-20" />
      </button>
    </div>
</div>
</template>
