import ApiService from "@/services/ApiService";
import InteractionPropertyMapper from "@/services/InteractionPropertyMapper";

// export 'mutations' as a named export for easier testing
export const mutations = {
  invalidateSessionToken(state) {
    state.sessionTokenValid = false;
  },
  setOldScoUser(state, scoUser) {
    state.oldScoUser = scoUser;
  },
  setStudent(state, student) {
    state.student = student;
  },
  setLogLines(state, logLines) {
    state.logLines = logLines;
  },
  setLastError(state, { CMIErrorCode, details }) {
    state.lastError.CMIErrorCode = CMIErrorCode;
    state.lastError.details = details;
  },
  clearLastError(state) {
    state.lastError.CMIErrorCode = 0;
    state.lastError.details = "No error";
  },
  patchNewScoUser(state, { property, value }) {
    state.newScoUser[property] = value;
  },
  patchNewScoUserInteraction(state, { primaryIndex, property, value }) {
    if (!Object.prototype.hasOwnProperty.call(state.newScoUser.interactions, primaryIndex)) {
      state.newScoUser.interactions[primaryIndex] = {};
    }
    let mapper = new InteractionPropertyMapper();
    state.newScoUser.interactions[primaryIndex][mapper.getProperty(property)] = value;
        // state.logLines.push({
        //   text: "Array Index: " + primaryIndex + ", " + mapper.getProperty(property) + ": " + value,
        //   color: 'black'
        // });
  },
  patchNewScoUserPostedInteractions(state, postedInteractions) {
    postedInteractions.forEach((p) => {
      state.newScoUser.posted_interactions.push(p);
    });
  },
  patchNewScoUserInteractionObjective(state, { primaryIndex, secondaryIndex, property, value }) {
    if (!state.newScoUser.interactions[primaryIndex]) {
      state.newScoUser.interactions.push({});
    }

    if (!state.newScoUser.interactions[primaryIndex].objectives) {
      state.newScoUser.interactions[primaryIndex].objectives = [];
    }

    if (!state.newScoUser.interactions[primaryIndex].objectives[secondaryIndex]) {
      state.newScoUser.interactions[primaryIndex].objectives.push({});
    }

    let mapper = new InteractionPropertyMapper();
    state.newScoUser.interactions[primaryIndex].objectives[secondaryIndex][mapper.getProperty(property)] = value;
  },
  patchNewScoUserInteractionCorrectResponse(state, { primaryIndex, secondaryIndex, property, value }) {
    if (!state.newScoUser.interactions[primaryIndex]) {
      state.newScoUser.interactions.push({});
    }

    if (!state.newScoUser.interactions[primaryIndex].correctResponses) {
      state.newScoUser.interactions[primaryIndex].correctResponses = [];
    }

    if (!state.newScoUser.interactions[primaryIndex].correctResponses[secondaryIndex]) {
      state.newScoUser.interactions[primaryIndex].correctResponses.push({});
    }
    let mapper = new InteractionPropertyMapper();
    state.newScoUser.interactions[primaryIndex].correctResponses[secondaryIndex][mapper.getProperty(property)] = value;
  },
  reset(state) {
    state.oldScoUser = {
      id: null,
      group_id: null,
      subgroup_id: null,
      virtual_group_id: null,
      licence_id: null,
      index_url: "",
      launch_data: "",
      suspend_data: "",
      lesson_location: "",
      lesson_status: "",
      total_time: "",
      entry_value: "",
      raw_score: 0,
      min_score: 0,
      max_score: 0,
      exit_value: "",
      session_time: "",
      credit: "",
      accessed: 0,
      attempts: 0,
      attempt_system_id: null,
      interactions: {},
      interactionsOffset: 0,
      posted_interactions: [],
      course_creator: "i2d2",
      sco: {
        title: ""
      }
    };
    state.newScoUser = {
      id: null,
      interactions: {},
      posted_interactions: []
    };
    state.sessionTokenValid = true;
  }
};

export default {
  namespaced: true,
  state: {
    oldScoUser: {
      id: null,
      group_id: null,
      subgroup_id: null,
      virtual_group_id: null,
      licence_id: null,
      index_url: "",
      launch_data: "",
      suspend_data: "",
      lesson_location: "",
      lesson_status: "",
      total_time: "",
      entry_value: "",
      raw_score: 0,
      min_score: 0,
      max_score: 0,
      exit_value: "",
      session_time: "",
      credit: "",
      accessed: 0,
      attempts: 0,
      attempt_system_id: null,
      interactions: {},
      interactionsOffset: 0,
      posted_interactions: [],
      course_creator: "i2d2",
      sco: {
        title: ""
      }
    },
    newScoUser: {
      id: null,
      interactions: {},
      posted_interactions: []
    },
    sessionTokenValid: true,
    student: {
      id: null,
      name: null
    },
    lastError: {
      CMIErrorCode: 0,
      details: ""
    },
    lastErrorDictionary: {
      0: "No error",
      101: "General Exception",
      201: "Invalid argument error",
      202: "Element cannot have children",
      203: "Element not an array. Cannot have count",
      205: "Incorrect data type.",
      301: "Not initialized",
      401: "Not implemented error",
      402: "Invalid set value, element is a keyword",
      403: "Element is read only",
      404: "Element is write only",
      405: "Incorrect Data Type"
    },
    logLines: []
  },
  getters: {
    getScoUserFieldValue: (state) => (field) => {
      if (Object.prototype.hasOwnProperty.call(state.newScoUser, field)) {
        return state.newScoUser[field];
      }

      return state.oldScoUser[field];
    },
    getLastErrorString(state) {
      return state.lastErrorDictionary[state.lastError.CMIErrorCode];
    },
    getScoUserIneractionsObjectivesCount: (state) => (interactionIndex) => {
      if (
        state.newScoUser.interactions[interactionIndex] &&
        state.newScoUser.interactions[interactionIndex].observations
      ) {
        return state.newScoUser.interactions[interactionIndex].observations.length;
      } else {
        return 0;
      }
    },
    getScoUserIneractionsCorrectResponsesCount: (state) => (interactionIndex) => {
      if (
        state.newScoUser.interactions[interactionIndex] &&
        state.newScoUser.interactions[interactionIndex].correctResponses
      ) {
        return state.newScoUser.interactions[interactionIndex].correctResponses;
      } else {
        return 0;
      }
    }
  },
  mutations,
  actions: {
    fetchScoUser(context, scoUserId) {
      return new Promise((resolve, reject) => {
        ApiService.fetchScoUser(scoUserId)
          .then((response) => {
            context.commit("setOldScoUser", response.data.scoUser);
            context.commit("patchNewScoUser", { property: "id", value: response.data.scoUser.id });
            context.commit("setStudent", response.data.student);
            context.commit("setLogLines", []);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};
