<script setup>
defineProps({
  data: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  }
});

import RTableHeaderRow from "@/components/RTableHeaderRow.vue";
import RTableRow from "@/components/RTableRow.vue";
import RTableCell from "@/components/RTableCell.vue";

// recursive function to get nested field values and handle dot notation
const fieldValue = (obj, field) => {
  //if field is an array join with space
  if (Array.isArray(field)) {
    return field.map((f) => fieldValue(obj, f)).join(" ");
  }

  if (field.includes(".")) {
    const [first, ...rest] = field.split(".");
    return fieldValue(obj[first], rest.join("."));
  }

  return obj[field];
};
</script>
<template>
  <div class="r-table">
    <template v-if="data.length > 0">
      <r-table-header-row>
        <r-table-cell v-for="(column, index) in columns" :key="index">{{ column.heading }}</r-table-cell>
      </r-table-header-row>
      <r-table-row
        v-for="(datum, index) in data"
        :key="index">
        <r-table-cell
          v-for="(column, subIndex) in columns"
          :heading="column.heading" :key="subIndex"
          >{{ fieldValue(datum, column.field) }}</r-table-cell
        >
      </r-table-row>
    </template>
    <slot></slot>
  </div>
</template>
