import auth from "@/middleware/auth.js";
import store from "@/store/index";

const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SubGroupAdminSubgroupsLayout",
    component: () => import(/* webpackChunkName: "subgroupAdmin" */ "@/views/subgroup_admin/subgroups/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "",
        name: "SubgroupAdminSubgroups",
        component: () =>
          import(
            /* webpackChunkName: "subgroupAdmin" */
            "@/views/subgroup_admin/subgroups/Subgroups.vue"
          ),
        meta: {
          middleware: auth
        },
      },
      {
        //when a service manager is viewing assessments due - if the
        //vg is by membership only, we can't know a specific subgroup
        //so we've got this duplicate of the historic info page with no suggroup_id required
        path: "historic-virtual-group/info/:virtualGroupId",
        name: "SubgroupAdminHistoricVirtualGroupInfoNoSubgroup",
        component: () =>
          import(
            /* webpackChunkName: "subgroupAdmin" */
            "@/views/common/groups/virtual_groups/HistoricVirtualGroupInfo.vue"
          ),
        meta: {
          middleware: [auth]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            virtualGroupId
          };
        }
      },
      {
        path: ":groupId/:subgroupId",
        name: "SubgroupAdminSubgroupLayout",
        component: () =>
          import(
            /* webpackChunkName: "subgroupAdmin" */
            "@/views/subgroup_admin/subgroups/SubgroupLayout.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          return {
            groupId,
            subgroupId
          };
        },
        beforeEnter: (to, from, next) => {
          Promise.all([
            store.dispatch("admin/primeCurrentSubgroup", {
              subgroupId: parseInt(to.params.subgroupId, 10)
            }),
            store.dispatch("admin/primeCurrentGroup", {
              groupId: parseInt(to.params.groupId, 10)
            })
          ])
            .then(() => {
              next();
            })
            .catch(() => {
              next();
            });
        },
        children: [
          {
            path: "booking-enquiries",
            name: "SubgroupAdminBookings",
            component: () => import("@/views/common/booking_organisers/BookingProjects.vue"),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "booking-enquiries/:projectRequestId/book",
            name: "SubgroupAdminBookingsBook",
            component: () => import("@/views/common/booking_organisers/BookingProjectsBook.vue"),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let projectRequestId = parseUrlParamToInt(route, "projectRequestId");
    
              return {
                groupId,
                subgroupId,
                projectRequestId
              };
            }
          },
          {
            path: "edit-user/:userId",
            name: "SubgroupAdminSubgroupEditUser",
            component: () => import(/* webpackChunkName: "subgroupAdmin" */ "@/views/common/EditUser.vue"),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let userId = parseUrlParamToInt(route, "userId");
              let groupingLevel = "subgroup";
              let apiAuthLevel = "subgroupAdmin";
              return {
                userId,
                groupingLevel,
                apiAuthLevel,
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "new-user",
            name: "SubgroupAdminSubgroupNewUser",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/CreateNewUser.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "bulk-upload-new-users",
            name: "SubgroupAdminSubgroupBulkNewUsers",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/BulkUploadNewUsers.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "members",
            name: "SubgroupAdminSubgroupMembers",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/GroupingMembers.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let groupingLevel = "subgroup";
              let adminLevel = "subgroupAdmin";
              let apiAuthLevel = "groupingAdmin";
              let editUserRoute = {
                name: "SubgroupAdminSubgroupEditUser",
                params: {
                  userId: null
                }
              };
              let userInfoRoute = {
                name: "SubgroupAdminSubgroupMembersInfo",
                params: {
                  subgroupId: subgroupId,
                  userId: null
                }
              };
              let licencesRoute = {
                name: "SubgroupAdminSubgroupMembersLicences",
                params: {
                  subgroupId: subgroupId,
                  userId: null
                }
              };
              return {
                groupingLevel,
                groupId,
                subgroupId,
                adminLevel,
                apiAuthLevel,
                editUserRoute,
                userInfoRoute,
                licencesRoute
              };
            }
          },
          {
            path: "membership",
            name: "SubgroupAdminSubgroupMembership",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/SubgroupMembership.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

              return {
                groupId,
                subgroupId,
                virtualGroupId
              };
            }
          },
          {
            path: "bulk-password-reset",
            name: "SubgroupAdminSubgroupBulkPasswordReset",
            component: () =>
              import(/* webpackChunkName: "commonAdmin" */ "@/views/common/groups/BulkPasswordReset.vue"),
            meta: {
              middleware: [auth]
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");

              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "members-licences/:userId",
            name: "SubgroupAdminSubgroupMembersLicences",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/MembersLicences.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let userId = parseUrlParamToInt(route, "userId");

              return {
                groupId,
                subgroupId,
                userId
              };
            }
          },
          {
            path: "members-info/:userId",
            name: "SubgroupAdminSubgroupMembersInfo",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/MembersInfo.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let userId = parseUrlParamToInt(route, "userId");
              return {
                groupId,
                subgroupId,
                userId
              };
            }
          },
          {
            path: "issue-licence/:userId",
            name: "SubgroupAdminSubgroupIssueLicence",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/IssueLicence.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let userId = parseUrlParamToInt(route, "userId");
              return {
                groupId,
                subgroupId,
                userId
              };
            }
          },
          {
            path: "licences",
            name: "SubgroupAdminSubgroupLicences",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/licences/SubgroupLicences.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "request-licences",
            name: "SubgroupAdminSubgroupRequestLicences",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/licences/RequestOrOrderLicences.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let adminLevel = "subgroupAdmin";
              let actionType = "request";

              return {
                groupId,
                subgroupId,
                adminLevel,
                actionType
              };
            }
          },
          {
            path: "order-licences",
            name: "SubgroupAdminSubgroupOrderLicences",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/licences/RequestOrOrderLicences.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let adminLevel = "subgroupAdmin";
              let actionType = "order";

              return {
                groupId,
                subgroupId,
                adminLevel,
                actionType
              };
            }
          },
          {
            path: "licences/bulk-issue-from-general-pot/:abstractCourseId/:available/:certificateMonths",
            name: "SubgroupAdminBulkAllocateGroupSubgroupLicenceToMembersFromPot",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/licences/SubgroupBulkIssueLicences.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
              let available = parseUrlParamToInt(route, "available");
              let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

              return {
                groupId,
                subgroupId,
                abstractCourseId,
                available,
                source: "pot",
                certificateMonths
              };
            }
          },
          {
            path: "licences/bulk-issue-from-pool/:abstractCourseId/:available/:certificateMonths",
            name: "SubgroupAdminBulkAllocateGroupSubgroupLicenceToMembersFromPool",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/licences/SubgroupBulkIssueLicences.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
              let available = parseUrlParamToInt(route, "available");
              let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

              return {
                groupId,
                subgroupId,
                abstractCourseId,
                available,
                source: "pool",
                certificateMonths
              };
            }
          },
          {
            path: "settings",
            name: "SubgroupAdminSettings",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/Settings.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");

              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "virtual-groups",
            name: "SubgroupAdminVirtualGroups",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/VirtualGroups.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              return {
                groupId,
                subgroupId
              };
            }
          },
          {
            path: "historic-virtual-groups",
            name: "SubgroupAdminHistoricVirtualGroups",
            component: () =>
              import(
                /* webpackChunkName: "commonAdmin" */
                "@/views/common/groups/subgroups/virtual_groups/SubgroupHistoricVirtualGroups.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let infoRouteName = "SubgroupAdminHistoricVirtualGroupInfo";
              return {
                groupId,
                subgroupId,
                infoRouteName
              };
            }
          },
          {
            path: "virtual-groups/:virtualGroupId/edit",
            name: "SubgroupAdminVirtualGroupEdit",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/VirtualGroupEdit.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
              return {
                groupId,
                subgroupId,
                virtualGroupId
              };
            }
          },
          {
            path: "virtual-groups/:virtualGroupId/members",
            name: "SubgroupAdminVirtualGroupMembers",
            component: () => import(/* webpackChunkName: "subgroupAdmin" */ "@/views/common/GroupingMembers.vue"),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
              let groupingLevel = "virtual group";
              let adminLevel = "subgroupAdmin";
              let apiAuthLevel = "groupingAdmin";
              let editUserRoute = {
                name: "SubgroupAdminSubgroupEditUser",
                params: {
                  userId: null
                }
              };
              let userInfoRoute = {
                name: "SubgroupAdminSubgroupMembersInfo",
                params: {
                  subgroupId: subgroupId,
                  userId: null
                }
              };
              //not used for vgs but needed by component prop validation
              let licencesRoute = {
                name: "SubgroupAdminSubgroupMembersLicences",
                params: {
                  subgroupId: subgroupId,
                  userId: null
                }
              };
              return {
                groupingLevel,
                groupId,
                subgroupId,
                virtualGroupId,
                adminLevel,
                apiAuthLevel,
                editUserRoute,
                userInfoRoute,
                licencesRoute
              };
            }
          },
          //Note we're using the same view that's used by group level
          {
            path: "historic-virtual-group/info/:virtualGroupId",
            name: "SubgroupAdminHistoricVirtualGroupInfo",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/common/groups/virtual_groups/HistoricVirtualGroupInfo.vue"
              ),
            meta: {
              middleware: [auth]
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

              return {
                groupId,
                subgroupId,
                virtualGroupId
              };
            }
          },
          {
            path: "virtual-groups/:virtualGroupId/membership",
            name: "SubgroupAdminVirtualGroupMembership",
            component: () =>
              import(
                /* webpackChunkName: "subgroupAdmin" */
                "@/views/subgroup_admin/subgroups/SubgroupVirtualGroupMembership.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

              return {
                groupId,
                subgroupId,
                virtualGroupId
              };
            }
          },
          {
            path: "virtual-groups/:virtualGroupId/bulk-upload-new-users",
            name: "SubgroupAdminVirtualGroupBulkNewUsers",
            component: () =>
              import(
                /* webpackChunkName: "siteAdmin" */
                "@/views/subgroup_admin/subgroups/BulkUploadVirtualGroupNewUsers.vue"
              ),
            meta: {
              middleware: auth
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let subgroupId = parseUrlParamToInt(route, "subgroupId");
              let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

              return {
                groupId,
                subgroupId,
                virtualGroupId
              };
            }
          }
        ]
      }
    ]
  },
  {
    path: "reports",
    name: "SubgroupAdminReportsLayout",
    component: () => import(/* webpackChunkName: "subgroupAdmin" */ "@/views/subgroup_admin/reports/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "",
        name: "SubgroupAdminReports",
        redirect: {
          name: "SubgroupAdminReportsCertificateExpiries"
        }
      },
      {
        path: "certificate-expiries",
        name: "SubgroupAdminReportsCertificateExpiries",
        component: () =>
          import(
            /* webpackChunkName: "subgroupAdmin" */
            "@/views/subgroup_admin/reports/CertificateExpiries.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "licence-levels",
        name: "SubgroupAdminReportsLicenceLevels",
        component: () =>
          import(/* webpackChunkName: "subgroupAdmin" */ "@/views/subgroup_admin/reports/LowLicenceLevels.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "results",
        name: "SubgroupAdminReportsCourseResults",
        component: () => import(/* webpackChunkName: "subgroupAdmin" */ "@/views/common/reports/CourseResults.vue"),
        meta: {
          middleware: auth
        },
        props: { adminLevel: "subgroupAdmin" }
      },
      {
        path: "assessments-due",
        name: "SubgroupAdminReportsAssessmentsDue",
        component: () => import(/* webpackChunkName: "subgroupAdmin" */ "@/views/common/reports/AssessmentsDue.vue"),
        meta: {
          middleware: auth
        }
      }
    ]
  }
];
