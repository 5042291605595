import { createRouter, createWebHistory } from "vue-router";
import Icons from "@/views/Icons.vue";
import Home from "@/views/Home.vue";
// import Courses from "@/views/personal/Courses.vue";
import MyCourses from "@/views/personal/MyCourses.vue";
import auth from "@/middleware/auth.js";
import store from "@/store";
import siteAdminChildren from "@/router/site_admin/siteAdminChildren.js";
import groupAdminChildren from "@/router/group_admin/groupAdminChildren.js";
import subgroupAdminChildren from "@/router/subgroup_admin/subgroupAdminChildren.js";
import virtualGroupAdminChildren from "@/router/virtual_group_admin/virtualGroupAdminChildren.js";
import NetworkConnectivity from "@/views/NetworkConnectivity.vue";
import SiteMaintenance from "@/views/SiteMaintenance.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import ApiService from "@/services/ApiService";


const routes = [
  {
    path: "/icons",
    component: Icons
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("@/views/Faq.vue"),
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = true;
      next();
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = true;
      next();
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditionsPage",
    component: () => import("@/views/TermsAndConditionsPage.vue"),
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = true;
      next();
    }
  },
  {
    path: "/member-name-change/confirmation-token/:tokenId/:tokenKey/:action",
    name: "NameChangeProcessing",
    component: () => import("@/views/NameChangeProcessing.vue"),
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = true;
      next();
    }
  },
  {
    path: "/",
    component: Home,
    meta: {
      middleware: auth
    },
    children: [
      {
        name: "Home",
        path: "",
        redirect: {
          name: "MyCourses"
        }
      },
      {
        name: "SiteAdmin",
        path: "site-admin",
        redirect: {
          name: "SiteAdminCourses"
        },
        component: () => import(/* webpackChunkName: "siteAdmin" */ "../views/site_admin/SiteAdmin.vue"),
        meta: {
          middleware: auth
        },
        beforeEnter: (to, from, next) => {
          store.state.showHeader = false;
          store.state.showNav = true;
          store.state.navigation.sub = "admin";
          next();
        },
        children: [...siteAdminChildren]
      },
      {
        name: "GroupAdmin",
        path: "group-admin",
        redirect: {
          name: "GroupAdminGroups"
        },
        component: () => import(/* webpackChunkName: "groupAdmin" */ "../views/group_admin/GroupAdmin.vue"),
        meta: {
          middleware: auth
        },
        beforeEnter: (to, from, next) => {
          store.state.showHeader = false;
          store.state.showNav = true;
          next();
        },
        children: [...groupAdminChildren]
      },
      {
        name: "SubgroupAdmin",
        path: "subgroup-admin",
        redirect: {
          name: "SubgroupAdminSubgroups"
        },
        component: () => import(/* webpackChunkName: "subgroupAdmin" */ "../views/subgroup_admin/SubgroupAdmin.vue"),
        meta: {
          middleware: auth
        },
        beforeEnter: (to, from, next) => {
          store.state.showHeader = false;
          store.state.showNav = true;
          next();
        },
        children: [...subgroupAdminChildren]
      },
      {
        name: "VirtualGroupAdminRedirect",
        path: "virtual-group-admin",
        redirect:  { name: "VirtualGroupAdminVirtualGroups" }
      },
      {
        name: "VirtualGroupAdmin",
        path: "trainers",
        redirect: { name: "VirtualGroupAdminVirtualGroups" },
        component: () =>
          import(
            /* webpackChunkName: "virtualGroupAdmin" */
            "../views/virtual_group_admin/VirtualGroupAdmin.vue"
          ),
        meta: {
          middleware: auth
        },
        beforeEnter: (to, from, next) => {
          store.state.showHeader = false;
          store.state.showNav = true;
          next();
        },
        children: [...virtualGroupAdminChildren]
      },
      {
        name: "Notifications",
        path: "notifications",
        component: () =>
          import(
            /* webpackChunkName: "notifications" */
            "../views/common/Notifications.vue"
          ),
        meta: {
          middleware: auth
        },
        beforeEnter: (to, from, next) => {
          store.state.showHeader = false;
          store.state.showNav = true;
          next();
        },
      }
    ]
  },
  {
    path: "/my-learning-zone",
    alias: "/my-courses", //this is for backwards compatibility with old links
    component: MyCourses,
    meta: {
      middleware: auth
    },
    beforeEnter: (to, from, next) => {
      // store.state.showHeader = true;
      // store.state.showNav = true;
      store.state.showHeader = false;
      store.state.showNav = false;
      store.state.learningZoneTab = "general";
      next();
    },
    children: [
      {
        path: "",
        name: "MyCourses",
        component: () => import("@/views/personal/my_courses/Courses.vue"),
      },
      {
        path: "course-launch/:courseType/:polyCourseId/:groupId?",
        name: "CourseLaunch",
        component: () => import("@/views/personal/my_courses/CourseLaunch.vue"),
        props: (route) => {
          let polyCourseId = Number.parseInt(route.params.polyCourseId, 10);
          let courseType = route.params.courseType;
          let groupId = route.params.groupId ? Number.parseInt(route.params.groupId, 10) : null;
          return { courseType, polyCourseId, groupId }
        }
      },
      {
        path: "modules/:courseType/:courseId/:groupId?",
        name: "MyCoursesModules",
        component: () => import("@/views/personal/my_courses/Modules.vue"),
        props: (route) => {
          let courseId = Number.parseInt(route.params.courseId, 10);
          let courseType = route.params.courseType;
          let groupId = route.params.groupId ? Number.parseInt(route.params.groupId, 10) : null;
          return { courseType, courseId, groupId }
        }
      },
      {
        path: "account",
        name: "Account",
        component: () => import(/* webpackChunkName: "frontend" */ "../views/Account.vue"),
        meta: {
          middleware: auth
        },
      },
      {
        path: "resources",
        name: "Resources",
        component: () => import(/* webpackChunkName: "frontend" */ "../views/personal/my_courses/Resources.vue"),
        meta: {
          middleware: auth
        },
      },
      {
        path: "/lms-content/:scoUserId/:slug",
        name: "Content",
        component: () => import(/* webpackChunkName: "content" */ "../views/personal/Content.vue"),
        meta: {
          middleware: auth
        },
        beforeEnter: (to, from, next) => {
          store.state.showHeader = false;
          store.state.showNav = false;
          next();
        },
        props: (route) => {
          let scoUserId = Number.parseInt(route.params.scoUserId, 10);
          if (Number.isNaN(scoUserId)) {
            scoUserId = 0;
          }
          return {
            scoUserId
          };
        }
      },
    ]
  },
  {
    path: "/lms-content-duplicate-tab",
    name: "ContentDuplicateTab",
    component: () => import(/* webpackChunkName: "content" */ "../views/personal/ContentDuplicateTab.vue"),
    meta: {
      middleware: auth
    },
    beforeEnter: (to, from, next) => {
      store.state.showHeader = false;
      store.state.showNav = false;
      next();
    }
  },
  {
    path: "/results/:scoUserId/:slug",
    name: "Results",
    component: () => import(/* webpackChunkName: "content" */ "../views/personal/CompetencyResults.vue"),
    meta: {
      middleware: auth
    },
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = true;
      next();
    },
    props: (route) => {
      let scoUserId = Number.parseInt(route.params.scoUserId, 10);
      if (Number.isNaN(scoUserId)) {
        scoUserId = 0;
      }
      return {
        scoUserId
      };
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "frontend" */ "../views/Login.vue"),
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = false;
      next();
    }
  },
  {
    path: "/signout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "frontend" */ "../views/Logout.vue")
  },
  {
    path: "/forgotten-password",
    name: "ForgottenPassword",
    component: () => import(/* webpackChunkName: "frontend" */ "../views/ForgottenPassword.vue")
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: () => import(/* webpackChunkName: "passwordReset" */ "../views/PasswordReset.vue")
  },
  {
    path: "/accept-invite/:token",
    name: "AcceptInvite",
    component: () => import("../views/personal/AcceptInvitationToJoin.vue"),
    beforeEnter: (to, from, next) => {
      store.state.showHeader = true;
      store.state.showNav = true;
      next();
    },
    props: true
  },
  {
    path: "/network-connectivity",
    name: "NetworkConnectivity",
    component: NetworkConnectivity
  },
  {
    path: "/503",
    name: "SiteMaintenance",
    component: SiteMaintenance
  },
  {
    path: "/404",
    name: "Error404",
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
      //because async loading times vary I've abandoned this for now
      //but if the element is condtionally shown it may be necessary
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve({ el: to.hash, behavior: 'smooth'})
      //   }, 300)
      // })
    } else {
      return {
        top: 0
      };
    }
  }
});

router.beforeResolve(function (to, from, next) {
  to.matched.length
    ? next()
    : next({
        name: "Error404"
      });
});

//see: https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    });
  };
}

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  //we want to prevent user
  if (to.name !== "ContentDuplicateTab" && from.name === "Content" && window.API && !window.API.flagFinished) {
    if (
      window.confirm(
        "Please use the 'Exit' button at the bottom of the screen to leave, " +
          "that will ensure your progress is saved.\n\n" +
          "Press 'OK' to stay on this page so that you can then use the 'Exit' button."
      )
    ) {
      return next(false);
    } else {
      //we'll try and save their progress anyway
      window.API.flagFinished = true;
      ApiService.finish(store.state.scorm_1_2.newScoUser.id, store.state.scorm_1_2.newScoUser)
        .then(() => {
          return next();
        })
        .catch(() => {
          return next();
        });
    }
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    /*
        whilst we're only using an auth middleware this isn't a problem,
        if we add any other type of middleware we may need to move this to after the metadata and priming code
         */
    return middleware[0]({
      ...context,
      next: nextMiddleware
    });
  }

  try {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (nearestWithMeta) {
      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags
        .map((tagDef) => {
          const tag = document.createElement("meta");

          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
          });

          // We use this to track which meta tags we create, so we don't interfere with other ones.
          tag.setAttribute("data-vue-router-controlled", "");

          return tag;
        })
        .forEach((tag) => document.head.appendChild(tag));
    }
  } catch (err) {
    //console.log(err);
  }

  next();
});

export default router;
