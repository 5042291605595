const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "pharmacist-led-training",
    name: "VirtualGroupAdminVirtualGroups",
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/virtual_groups/VirtualGroups.vue"
      ),
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");

      return { groupId };
    }
  },
  {
    path: "booking-confirmation-enquiries",
    name: "VirtualGroupAdminBookingConfirmationEnquiries",
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/booking_confirmations/BookingConfirmationEnquiries.vue"
      )
  },
  {
    path: "availability-enquiries",
    name: "VirtualGroupAdminAvailabilityEnquiries",
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/availability/AvailabilityEnquiries.vue"
      )
  },
  {
    path: "general-availability",
    name: "VirtualGroupAdminGeneralAvailabilityCalendar",
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/availability/GeneralAvailabilityCalendarView.vue"
      )
  },
  {
    path: "schedule",
    name: "VirtualGroupAdminTrainerScheduleLayout",
    redirect: { name: "VirtualGroupAdminTrainerSchedule", params: { period: "week" } },
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/availability/ScheduleLayout.vue"
      ),
    children: [
      {
        path: ":period",
        name: "VirtualGroupAdminTrainerSchedule",
        component: () =>
          import(
            /* webpackChunkName: "virtualGroupAdmin" */
            "@/views/virtual_group_admin/availability/Schedule.vue"
          ),
        props: true
      }
    ]
  },
  {
    path: "third-party-calendars",
    name: "VirtualGroupAdminThirdPartyCalendars",
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/availability/ThirdPartyCalendars.vue"
      ),
  },
  {
    path: ":virtualGroupId",
    name: "VirtualGroupAdminVirtualGroupLayout",
    component: () =>
      import(
        /* webpackChunkName: "virtualGroupAdmin" */
        "@/views/virtual_group_admin/virtual_groups/VirtualGroupLayout.vue"
      ),
    props: (route) => {
      let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

      return { virtualGroupId };
    },
    children: [
      {
        path: "members",
        name: "VirtualGroupAdminVirtualGroupMembers",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/GroupingMembers.vue"
          ),
        props: (route) => {
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let groupId = null;
          let groupingLevel = "virtual group";
          let adminLevel = "virtualGroupAdmin";
          let apiAuthLevel = "groupingAdmin";
          let editUserRoute = {
            name: "NA",
            params: { userId: null }
          };
          let userInfoRoute = {
            name: "VirtualGroupAdminVirtualGroupMembersInfo",
            params: { virtualGroupId: virtualGroupId, userId: null }
          };
          let licencesRoute = {
            name: "VirtualGroupAdminVirtualGroupMembersLicences",
            params: { virtualGroupId: virtualGroupId, userId: null }
          };
          return {
            groupingLevel,
            groupId,
            virtualGroupId,
            adminLevel,
            apiAuthLevel,
            editUserRoute,
            userInfoRoute,
            licencesRoute
          };
        }
      },
      {
        path: "details",
        name: "VirtualGroupAdminVirtualGroupDetails",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/virtual_group_admin/virtual_groups/Details.vue"
          ),

        props: (route) => {
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          return {
            virtualGroupId
          };
        }
      },
      {
        path: "members-info/:userId",
        name: "VirtualGroupAdminVirtualGroupMembersInfo",
        component: () =>
          import(
            /* webpackChunkName: "virtualGroupAdmin" */
            "@/views/virtual_group_admin/virtual_groups/MembersInfo.vue"
          ),

        props: (route) => {
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return { virtualGroupId, userId };
        }
      },
      {
        path: "members-licences/:userId",
        name: "VirtualGroupAdminVirtualGroupMembersLicences",
        component: () =>
          import(
            /* webpackChunkName: "virtualGroupAdmin" */
            "@/views/virtual_group_admin/virtual_groups/MembersLicences.vue"
          ),

        props: (route) => {
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return { virtualGroupId, userId };
        }
      },
      {
        path: "issue-licence/:userId",
        name: "VirtualGroupAdminVirtualGroupIssueLicence",
        component: () =>
          import(
            /* webpackChunkName: "virtualGroupAdmin" */
            "@/views/virtual_group_admin/virtual_groups/IssueLicence.vue"
          ),

        props: (route) => {
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return { virtualGroupId, userId };
        }
      }
    ]
  }
];
