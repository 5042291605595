import auth from "@/middleware/auth.js";
import atOrgLevel from "@/middleware/atOrgLevel.js";
import groupSubgroupChildren from "@/router/group_admin/groupSubgroupChildren";
import groupVirtualGroupChildren from "@/router/group_admin/groupVirtualGroupChildren";
import store from "@/store";
const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "GroupAdminGroups",
    component: () => import("@/views/group_admin/groups/Groups.vue"),
    meta: {
      middleware: [auth, atOrgLevel]
    },
  },
  {
    path: ":groupId",
    name: "GroupAdminGroupGroupLayout",
    component: () => import("@/views/group_admin/groups/GroupLayout.vue"),
    meta: {
      middelware: auth
    },
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");

      return {
        groupId
      };
    },
    beforeEnter: (to, from, next) => {
      store
        .dispatch("admin/primeCurrentGroup", {
          groupId: parseInt(to.params.groupId, 10)
        })
        .then(() => {
          next();
        })
        .catch(() => {
          next();
        });
    },
    children: [
      {
        path: "booking-enquiries",
        name: "GroupAdminGroupBookings",
        component: () => import("@/views/common/booking_organisers/BookingProjects.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "booking-enquiries/:projectRequestId/book",
        name: "GroupAdminGroupBookingsBook",
        component: () => import("@/views/common/booking_organisers/BookingProjectsBook.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let projectRequestId = parseUrlParamToInt(route, "projectRequestId");

          return {
            groupId,
            projectRequestId
          };
        }
      },
      {
        path: "new-subgroup",
        name: "GroupAdminGroupSubgroupNew",
        component: () => import("@/views/group_admin/groups/subgroups/SubgroupNew.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "edit-user/:userId",
        name: "GroupAdminGroupEditUser",
        component: () => import("@/views/common/EditUser.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          let groupingLevel = "group";
          let apiAuthLevel = "groupingAdmin";
          return {
            userId,
            apiAuthLevel,
            groupingLevel,
            groupId
          };
        }
      },
      {
        path: "members",
        name: "GroupAdminGroupMembers",
        component: () => import("@/views/common/GroupingMembers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let groupingLevel = "group";
          let apiAuthLevel = "groupingAdmin";
          let editUserRoute = {
            name: "GroupAdminGroupEditUser",
            params: {
              userId: null
            }
          };
          let userInfoRoute = {
            name: "GroupAdminGroupMembersInfo",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          let licencesRoute = {
            name: "GroupAdminGroupMembersLicences",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          return {
            groupingLevel,
            groupId,
            apiAuthLevel,
            editUserRoute,
            userInfoRoute,
            licencesRoute
          };
        }
      },
      {
        path: "archived-members",
        name: "GroupAdminArchivedGroupMembers",
        component: () => import(/* webpackChunkName: "groupAdmin" */ "@/views/common/ArchivedGroupMembers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "bulk-password-reset",
        name: "GroupAdminGroupBulkPasswordReset",
        component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/groups/BulkPasswordReset.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "licences",
        name: "GroupAdminGroupLicences",
        component: () => import("@/views/common/licences/Licences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let adminLevel = "groupAdmin";

          return {
            groupId,
            adminLevel
          };
        }
      },
      {
        path: "order-licences",
        name: "GroupAdminGroupOrderLicences",
        component: () => import("@/views/common/licences/RequestOrOrderLicences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = null;
          let adminLevel = "groupAdmin";
          let actionType = "order";

          return {
            groupId,
            subgroupId,
            adminLevel,
            actionType
          };
        }
      },
      {
        path: "licences/:abstractCourseId/:certificateMonths",
        name: "GroupAdminGroupLicencesForCourse",
        component: () => import("@/views/common/licences/LicencesForCourse.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractCourseId,
            certificateMonths
          };
        }
      },
      {
        path: "licences/paper-course/:abstractPaperCourseId/:certificateMonths",
        name: "GroupAdminGroupLicencesForPaperCourse",
        component: () => import("@/views/common/licences/LicencesForPaperCourse.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractPaperCourseId = parseUrlParamToInt(route, "abstractPaperCourseId");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractPaperCourseId,
            certificateMonths
          };
        }
      },
      {
        path: "licences/:abstractCourseId/bulk-allocation-from-general-pot/:available/:certificateMonths",
        name: "GroupAdminGroupBulkAllocateGroupLicenceToMembers",
        component: () => import("@/views/common/licences/BulkIssueLicencesFromPot.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let available = parseUrlParamToInt(route, "available");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractCourseId,
            available,
            certificateMonths
          };
        }
      },
      {
        path: "licences/:abstractCourseId/pool-allocation/:available/:certificateMonths",
        name: "GroupAdminGroupAllocateGroupLicenceToPool",
        component: () => import("@/views/common/licences/AllocateGroupLicenceToPool.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let abstractCourseId = parseUrlParamToInt(route, "abstractCourseId");
          let available = parseUrlParamToInt(route, "available");
          let certificateMonths = parseUrlParamToInt(route, "certificateMonths");

          return {
            groupId,
            abstractCourseId,
            available,
            certificateMonths
          };
        }
      },

      {
        path: "members-licences/:userId/subgroup/:subgroupId",
        name: "GroupAdminGroupSubgroupMembersLicences",
        component: () => import("@/views/group_admin/groups/MembersLicences.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            subgroupId,
            userId
          };
        }
      },
      {
        path: "members-licences/:userId/virtual-group/:virtualGroupId",
        name: "GroupAdminGroupVirtualGroupMembersLicences",
        component: () => import("@/views/group_admin/groups/MembersLicences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            virtualGroupId,
            userId
          };
        }
      },
      {
        path: "members-licences/:userId",
        name: "GroupAdminGroupMembersLicences",
        component: () => import("@/views/group_admin/groups/MembersLicences.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId
          };
        }
      },
      {
        path: "members-info/:userId/subgroup/:subgroupId",
        name: "GroupAdminGroupSubgroupMembersInfo",
        component: () => import("@/views/group_admin/groups/MembersInfo.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            subgroupId,
            userId
          };
        }
      },
      {
        path: "members-info/:userId/virtual-group/:virtualGroupId",
        name: "GroupAdminGroupVirtualGroupMembersInfo",
        component: () => import("@/views/group_admin/groups/MembersInfo.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            virtualGroupId,
            userId
          };
        }
      },
      {
        path: "members-info/:userId",
        name: "GroupAdminGroupMembersInfo",
        component: () => import("@/views/group_admin/groups/MembersInfo.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId
          };
        }
      },

      {
        path: "issue-licence/:userId/subgroup/:subgroupId",
        name: "GroupAdminGroupSubgroupIssueLicence",
        component: () => import("@/views/group_admin/groups/IssueLicence.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let subgroupId = parseUrlParamToInt(route, "subgroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            subgroupId,
            userId
          };
        }
      },
      {
        path: "issue-licence/:userId/virtual-group/:virtualGroupId",
        name: "GroupAdminGroupVirtualGroupIssueLicence",
        component: () => import("@/views/group_admin/groups/IssueLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            virtualGroupId,
            userId
          };
        }
      },
      {
        path: "issue-licence/:userId",
        name: "GroupAdminGroupIssueLicence",
        component: () => import("@/views/group_admin/groups/IssueLicence.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let userId = parseUrlParamToInt(route, "userId");

          return {
            groupId,
            userId
          };
        }
      },

      {
        path: "subgroups",
        name: "GroupAdminGroupSubgroupsLayout",
        component: () => import("@/views/group_admin/groups/subgroups/Layout.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        },
        children: [...groupSubgroupChildren]
      },
      {
        path: "archived-subgroups",
        name: "GroupAdminGroupArchivedSubgroups",
        component: () => import("@/views/group_admin/groups/subgroups/ArchivedSubgroups.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "virtual-groups",
        name: "GroupAdminGroupVirtualGroupsLayout",
        component: () => import("@/views/group_admin/groups/virtual_groups/Layout.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        },
        children: [...groupVirtualGroupChildren]
      },

      {
        path: "historic-virtual-groups",
        name: "GroupAdminGroupHistoricVirtualGroups",
        component: () => import("@/views/common/groups/virtual_groups/HistoricVirtualGroups.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let infoRouteName = "GroupAdminGroupHistoricVirtualGroupInfo";

          return {
            groupId,
            infoRouteName
          };
        }
      },

      {
        path: "historic-virtual-group/info/:virtualGroupId",
        name: "GroupAdminGroupHistoricVirtualGroupInfo",
        component: () => import("@/views/common/groups/virtual_groups/HistoricVirtualGroupInfo.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            virtualGroupId
          };
        }
      },

      {
        path: "new-user",
        name: "GroupAdminGroupNewUser",
        component: () => import("@/views/group_admin/groups/CreateNewUser.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "bulk-upload-new-users",
        name: "GroupAdminGroupBulkNewUsers",
        component: () => import("@/views/group_admin/groups/BulkUploadNewUsers.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "settings",
        name: "GroupAdminGroupSettings",
        component: () => import("@/views/group_admin/groups/GroupSettings.vue"),
        meta: {
          middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        }
      },
      {
        path: "documents",
        name: "GroupAdminDocumentsLayout",
        component: () => import( /* webpackChunkName: "commonAdmin" */ "@/views/common/documents/Layout.vue"),
        meta: {
            middleware: [auth, atOrgLevel]
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");

          return {
            groupId
          };
        },
        children: [
          {
            path: "",
            name: "GroupAdminGroupDocuments",
            component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/Documents.vue"),
            meta: {
              middleware: [auth, atOrgLevel]
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
    
              return {
                groupId
              };
            }
          },
          {
            path: "new",
            name: "GroupAdminDocumentsNew",
            component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/DocumentForm.vue"),
            meta: {
              middleware: [auth, atOrgLevel]
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
    
              return {
                groupId
              };
            }
          },
          {
            path: "edit/:documentId",
            name: "GroupAdminDocumentsEdit",
            component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/DocumentForm.vue"),
            meta: {
              middleware: [auth, atOrgLevel]
            },
            props: (route) => {
              let groupId = parseUrlParamToInt(route, "groupId");
              let documentId = parseUrlParamToInt(route, "documentId");
    
              return {
                groupId,
                documentId
              };
            }
          }
        ]
    },
    ]
  }
];
