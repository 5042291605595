<script setup>
import store from "@/store";

const emit = defineEmits(['courseResumed', 'courseExited']);

const resumeCourse = () => {
  store.state.showContentExitModal = false;
  emit('courseResumed');
};

const exitCourse = () => {
  store.state.showContentExitModal = false;
  store.state.exitConfirmed = true;
  emit('courseExited');
};
</script>
<template>
  <div
    class="bg-black bg-opacity-75 fixed inset-0 z-50 
    grid place-content-center grid-flow-col auto-cols-max auto-rows-min">
    <div class="bg-white rounded-lg px-4 py-2 md:px-10 md:py-6 2xl:px-20 2xl:py-10">
      <IconQuestionCircle class="text-primary w-10 h-10 md:w-16 md:h-16 2xl:w-20 2xl:h-20 mx-auto" />
      <h1 class="my-4 2xl:my-8 text-sm md:text-2xl 2xl:text-5xl font-bold min-w-max text-center">
        Are you sure you want to exit?
      </h1>
      <p class="flex gap-4 justify-center">
        <button class="btn btn-white uppercase" @click.prevent="exitCourse()">Exit Course</button>
        <button class="btn btn-primary uppercase" @click.prevent="resumeCourse()">Resume Course</button>
      </p>
    </div>
  </div>
</template>
