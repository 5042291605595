<script setup>
import store from "@/store";
const emit = defineEmits(["clicked"]);

defineProps(["buttonText"]);
</script>
<template>
  <button
    class="btn btn-block mb-2 hover:bg-primary hover:text-white duration-300 
    text-black font-light xl:text-2xl xl:leading-tight px-2"
    @click.prevent="emit('clicked')">
    <div class="flex justify-start items-center gap-6 h-8">
      <div class="w-8 h-8">
        <slot></slot>
      </div>
      <transition name="delayedFade">
        <span v-if="store.state.menuExpanded">{{ buttonText }}</span>
      </transition>
    </div>
  </button>
</template>
<style scoped>
.active {
@apply bg-primary text-white;
}
</style>