<template>
    <div class="flex justify-start items-center text-xs border-t border-b border-primary bg-white py-2 px-12">
        <div class="mr-3 text-lg">Key:</div>

        <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><IconNotStarted class="w-full h-full text-primary" /></div>
            <span>Not Started</span>
        </div>
        <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><IconStarted  class="w-full h-full text-secondary" /></div>
            <span>Started</span>
        </div>
        <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><IconPassed  class="w-full h-full text-primary"  /></div>
            <span>Passed</span>
        </div>
        <!-- <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><completed-icon /></div>
            <span>Completed</span>
        </div> -->
        <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><IconFailed  class="w-full h-full text-primary" /></div>
            <span>Further Training Required</span>
        </div>
        <!-- <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><feedback-icon /></div>
            <span>Feedback</span>
        </div> -->
        <div class="flex items-center justify-start mr-8" v-if="!hideCertificateIcon">
            <div class="mr-1 h-6 w-6"><certificate-icon /></div>
            <span>Certificate</span>
        </div>
        <div class="flex items-center justify-start mr-8">
            <div class="mr-1 h-6 w-6"><reports-icon class="text-primary"/></div>
            <span>Report</span>
        </div>
    </div>
</template>

<script>
import CertificateIcon from "@/components/icons/CertificateIcon";
// import FeedbackIcon from "@/components/icons/FeedbackIcon";
import ReportsIcon from "@/components/icons/ReportsIcon";

export default {
    props: {
        hideCertificateIcon: {
            type: Number,
            default: 1
        }
    },
    components: {
        CertificateIcon,
        // FeedbackIcon,
        ReportsIcon
    }
};
</script>
