<template>
    <div>
        <icon-legend />
    </div>
</template>

<script>
import IconLegend from "@/components/icons/IconLegend.vue";

export default {
    components: {
        IconLegend
    }
};
</script>
