<template>
    <div
        @click.prevent.stop="$emit('clicked')"
        class="absolute z-50 top-2 right-2 w-8 h-8 border border-white
         text-white hover:bg-white hover:bg-opacity-50 duration-300 flex items-center justify-center"
    >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
</template>

<script>
export default {
    name: "CloseButton"
};
</script>
