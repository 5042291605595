<script setup>
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useHasSuperAdmin, useHasGroupAdmin, useHasSubgroupAdmin, useHasTrainerRole } from "@/composables/helpers";
import store from "@/store";
import SideNavButton from "@/views/personal/my_courses/partials/SideNavButton.vue";
import ContentExitModal from "@/views/personal/my_courses/partials/ContentExitModal.vue";

const router = useRouter();
const route = useRoute();

const pendingRoute = ref(null);

const hasAdminRole = computed(() => {
  if (useHasSuperAdmin.value || useHasGroupAdmin.value || useHasSubgroupAdmin.value || useHasTrainerRole.value)
    return true;

  return false;
});
const adminRoute = computed(() => {
  if (useHasSuperAdmin.value) return { name: "SiteAdmin" };
  if (useHasGroupAdmin.value) return { name: "GroupAdmin" };
  if (useHasSubgroupAdmin.value) return { name: "SubgroupAdmin" };
  if (useHasTrainerRole.value) return { name: "VirtualGroupAdmin" };

  return { name: "Home" };
});

const attemptNavigation = function (destinationRoute) {
  pendingRoute.value = destinationRoute;
  
  if (route.name === "Content") {
    // they've got a course open so we need to confirm navigation away
    store.state.showContentExitModal = true;
    return;
  }

  proceedWithNavigation();
};

const proceedWithNavigation = function () {
  store.state.showContentExitModal = false;
  if (window.innerWidth < 768) {
    store.state.menuExpanded = false;
  }

  if(pendingRoute.value === null) {
    pendingRoute.value = { name: 'MyCourses' };
  }
  router.push(pendingRoute.value);
};

const cancelNavigation = function () {
  store.state.showContentExitModal = false;
  pendingRoute.value = null;
};
</script>
<template>
  <div
    id="side-nav"
    class="fixed bg-[#f8f8f8] border-r border-gray-200 grow-0 shrink-0 overflow-auto duration-300 ease-in-out z-60"
    :class="{ expanded: store.state.menuExpanded }">
    <div class="flex flex-col justify-between h-screen gap-0">
      <div class="flex-0 p-4">
        <button
          @click.prevent="store.commit('toggleMenuExpanded')"
          class="flex w-full mb-6 justify-start pl-1">
          <IconHamburger
            class="w-10 h-10"
            v-if="!store.state.menuExpanded" />
          <IconCross
            class="w-10 h-10"
            v-else />
        </button>

        <SideNavButton
          @clicked="attemptNavigation({ name: 'MyCourses' })"
          button-text="My Courses"
          :class="{ active: route.name === 'MyCourses' || route.name === 'Content' }"
          ><IconHome class="w-full h-full"
        /></SideNavButton>
        <SideNavButton
          @clicked="attemptNavigation({ name: 'Account' })"
          button-text="My Profile"
          :class="{ active: route.name === 'Account' }"
          ><IconPersonCircle class="w-full h-full"
        /></SideNavButton>
        <SideNavButton
          @clicked="attemptNavigation({ name: 'Resources' })"
          button-text="Resources"
          :class="{ active: route.name === 'Resources' }"
          ><IconDownload class="w-full h-full"
        /></SideNavButton>
      </div>

      <div class="flex-0 p-4">
        <SideNavButton
          v-if="hasAdminRole"
          @clicked="attemptNavigation(adminRoute)"
          button-text="Admin"
          ><IconAdmin class="w-full h-full"
        /></SideNavButton>
        <SideNavButton
          @clicked="attemptNavigation({ name: 'Logout' })"
          button-text="Logout"
          ><IconLogout class="w-full h-full"
        /></SideNavButton>

        <div class="h-24 flex flex-col justify-end">
          <transition name="delayedFade">
            <div
              v-if="store.state.menuExpanded"
              class="p-4">
              <hr class="my-4 text-black" />
              <p class="text-xl text-black font-bold">
                Need help? Contact us on<br />
                <a
                  href="mailto:support@opuspharmserve.com"
                  class="text-primary text-base font-bold"
                  >support@opuspharmserve.com</a
                >
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <teleport to="body"
      ><transition name="fade"
        ><ContentExitModal
          v-if="store.state.showContentExitModal"
          @courseResumed="cancelNavigation"
          @courseExited="proceedWithNavigation" /></transition
    ></teleport>
  </div>
</template>
<style scoped>
#side-nav {
  display: none;
}

#side-nav.expanded {
  display: block;
  width: 100%;
}

@media screen and (min-width: 450px) {
  #side-nav.expanded {
    display: block;
    width: 352px;
  }
}

@media screen and (min-width: 768px) {
  #side-nav {
    display: block;
    width: 80px;
  }

  #side-nav.expanded {
    display: block;
    width: 352px;
  }
}
.delayedFade-enter-active {
  transition: all 300ms ease 150ms;
}

.delayedFade-leave-active {
  transition: all 50ms ease;
}

.delayedFade-enter-from,
.delayedFade-leave-to {
  opacity: 0;
}
</style>
