<template>
    <div class="table-key">
        <div class="pr-4 whitespace-nowrap text-xs xl:text-base text-primary font-bold">Table Key:</div>
        <div class="pr-4 flex items-center" v-for="(icon, index) in icons" :key="index">
            <button v-if="icon.btnCss" class="mr-2 btn-xs xl:btn-sm" :class="icon.btnCss">
                <component :is="icon.icon" class="table-icon" :class="`${icon.css}`" />
            </button>
            <component v-else :is="icon.icon" class="table-icon mr-1" :class="`${icon.css}`" />
            <span class="whitespace-nowrap text-xs xl:text-base">{{ icon.label }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableKey",
    props: {
        icons: {
            Type: Array,
            required: true
        }
    }
};
</script>