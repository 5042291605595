import auth from "@/middleware/auth.js";
import store from "@/store";

const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminGroupVirtualGroups",
    component: () =>
      import(
        /* webpackChunkName: "siteAdmin" */
        "@/views/site_admin/groups/virtual_groups/VirtualGroups.vue"
      ),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");

      return {
        groupId
      };
    },
    beforeEnter: (to, from, next) => {
      store
        .dispatch("admin/primeCurrentGroup", {
          groupId: parseInt(to.params.groupId, 10)
        })
        .then(() => {
          next();
        })
        .catch(() => {
          next();
        });
    }
  },

  {
    path: ":virtualGroupId",
    name: "SiteAdminGroupVirtualGroupLayout",
    component: () =>
      import(
        /* webpackChunkName: "siteAdmin" */
        "@/views/site_admin/groups/virtual_groups/VirtualGroupLayout.vue"
      ),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let groupId = parseUrlParamToInt(route, "groupId");
      let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

      return {
        groupId,
        virtualGroupId
      };
    },
    beforeEnter: (to, from, next) => {
      store
        .dispatch("admin/primeCurrentVirtualGroup", {
          virtualGroupId: parseInt(to.params.virtualGroupId, 10)
        })
        .then(() => {
          next();
        })
        .catch(() => {
          next();
        });
    },
    children: [
      {
        path: "edit",
        name: "SiteAdminGroupEditVirtualGroup",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/groups/virtual_groups/VirtualGroupEdit.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let adminLevel = "superAdmin";

          return {
            groupId,
            virtualGroupId,
            adminLevel
          };
        }
      },
      {
        path: "close-course",
        name: "SiteAdminGroupVirtualGroupCloseCourse",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/GroupingMembers.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");
          let superAdminCloseCourse = true;
          let editUserRoute = {
            name: "SiteAdminGroupEditUser",
            params: {
              userId: null
            }
          };
          let userInfoRoute = {
            name: "SiteAdminGroupMembersInfo",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          let licencesRoute = {
            name: "SiteAdminGroupVirtualGroupMembersLicences",
            params: {
              virtualGroupId: virtualGroupId,
              userId: null
            }
          };

          return {
            groupId,
            virtualGroupId,
            superAdminCloseCourse,
            editUserRoute,
            userInfoRoute,
            licencesRoute,
            groupingLevel: "virtual group",
            apiAuthLevel: "superAdmin"
          };
        }
      },
      {
        path: "members",
        name: "SiteAdminGroupVirtualGroupMembers",
        component: () =>
          import(
            /* webpackChunkName: "commonAdmin" */
            "@/views/common/GroupingMembers.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          let groupingLevel = "virtual group";
          let apiAuthLevel = "superAdmin";
          let editUserRoute = {
            name: "SiteAdminGroupEditUser",
            params: {
              userId: null
            }
          };
          let userInfoRoute = {
            name: "SiteAdminGroupMembersInfo",
            params: {
              groupId: groupId,
              userId: null
            }
          };
          let licencesRoute = {
            name: "SiteAdminGroupVirtualGroupMembersLicences",
            params: {
              virtualGroupId: virtualGroupId,
              userId: null
            }
          };
          return {
            groupingLevel,
            groupId,
            virtualGroupId,
            apiAuthLevel,
            editUserRoute,
            userInfoRoute,
            licencesRoute
          };
        }
      },
      {
        path: "membership",
        name: "SiteAdminGroupVirtualGroupMembership",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/groups/virtual_groups/VirtualGroupMembership.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            virtualGroupId
          };
        }
      },
      {
        path: "bulk-upload-new-users",
        name: "SiteAdminVirtualGroupBulkNewUsers",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/common/groups/virtual_groups/BulkUploadNewUsers.vue"
          ),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let groupId = parseUrlParamToInt(route, "groupId");
          let virtualGroupId = parseUrlParamToInt(route, "virtualGroupId");

          return {
            groupId,
            virtualGroupId
          };
        }
      }
    ]
  }
];
