<template>
    <div class="text-center flex flex-wrap justify-center">
        <h1 class="w-full my-20">No Internet Connection</h1>

        <p class="w-full text-center">
            If you stay on this page we'll keep checking for a connection, and redirect you when the connection is
            restored.
        </p>
        <div class="spinner-wrapper mx-auto mt-8" v-if="checking">
            <spinner-message display-type="component" message="Checking..." />
        </div>
    </div>
</template>

<script>
//to avoid an interceptor redirect loop, we'll use a raw instance of axios here
import axios from "axios";

export default {
    name: "NetworkConnectivity",
    data() {
        return {
            intervalChecker: null,
            checking: false
        };
    },
    mounted() {
        this.intervalChecker = window.setInterval(this.checkForSiteUp, 10000);
    },
    beforeUnmount() {
        if (this.intervalChecker) {
            window.clearInterval(this.intervalChecker);
        }
    },
    methods: {
        checkForSiteUp() {
            let that = this;
            this.checking = true;

            axios
                .get("/online")
                .then(() => {
                    //site's back up
                    let redirectRoute;
                    if (typeof Storage !== "undefined") {
                        redirectRoute = window.localStorage.getItem("preInteruptRoute");
                        if (redirectRoute) {
                            window.localStorage.removeItem("preInteruptRoute");
                            this.$router.push(JSON.parse(redirectRoute));
                        } else {
                            this.$router.push({ name: "Home" });
                        }
                    } else {
                        this.$router.push({ name: "Home" });
                    }
                })
                .catch(() => {
                    window.setTimeout(() => {
                        that.checking = false;
                    }, 1000);
                });
        }
    }
};
</script>

<style>
.spinner-wrapper {
    width: min(600px, 100vw);
}
</style>
