<template>
  <div>
    <div
      v-if="$store.state.user && $store.state.user.demo && hasSuperAdmin && $store.state.navigation.sub === 'admin'"
      class="bg-red-500 text-white font-bold py-2 text-center">
      THIS IS THE DEMO SERVER
    </div>
    <div
      class="nav-wrapper overflow-y-auto"
      :class="{ active: $store.state.menuExpanded }"
      v-if="$store.state.user">
      <!-- prettier-ignore-attribute class -->
      <nav
        class="flex flex-row flex-wrap md:flex-nowrap justify-between bg-white w-full px-0 md:px-12 
        border-b border-t border-nav">
        <div class="flex flex-row flex-wrap w-full md:w-auto md:flex-nowrap">
          <div
            class="mx-auto my-2 w-20 md-my-0 md:ml-0 md:mr-6"
            v-if="hasAnAdminRole">
            <opus-logo
              @click.prevent="goHome()"
              class="w-full h-full flex items-center"
              v-if="!$store.state.showHeader" />
          </div>
          <router-link
            class="nav-item"
            exact-path
            :to="{ name: 'MyCourses' }"
            @click.prevent="gotoRoute({ name: 'MyCourses' })"
            >My Learning Zone</router-link
          >
          <router-link
            class="nav-item"
            v-if="hasSuperAdmin"
            :to="{ name: 'SiteAdmin' }"
            @click.prevent="gotoRoute({ name: 'SiteAdmin' })"
            >OPUS Admin</router-link
          >
          <router-link
            class="nav-item"
            v-if="hasGroupAdmin && !hasSuperAdmin"
            :to="{ name: 'GroupAdmin' }"
            @click.prevent="gotoRoute({ name: 'GroupAdmin' })"
            >{{ $t("Group") }} Management Zone</router-link
          >
          <router-link
            class="nav-item"
            v-if="hasSubgroupAdmin && !hasGroupAdmin && !hasSuperAdmin"
            :to="{ name: 'SubgroupAdmin' }"
            @click.prevent="gotoRoute({ name: 'SubgroupAdmin' })"
            >{{ $t("Subgroup") }} Management Zone</router-link
          >
          <router-link
            class="nav-item"
            v-if="hasVirtualGroupAdmin && !hasSuperAdmin"
            :to="{ name: 'VirtualGroupAdmin' }"
            @click.prevent="gotoRoute({ name: 'VirtualGroupAdmin' })"
            >{{ $t("Virtual Group") }} Management Zone</router-link
          >
        </div>
        <div
          class="mx-0 md:-mx-4 flex flex-row flex-wrap md:flex-nowrap w-full md:w-auto justify-center md:justify-start"
          v-if="$router.currentRoute.name !== 'Login'">
          <div
            v-if="$store.state.showHeader"
            class="w-full md:w-auto nav-item nav-item-wide ask-the-pharmacist"
            @click.prevent.stop="askThePharmacist = true">
            <span class="leading-none">
              <span class="text-vs md:text-xs text-dark-grey font-medium">Got a question?</span><br />
              <span class="text-vs md:text-xs text-dark-grey font-medium">ASK THE PHARMACIST</span>
            </span>
            <teleport to="body">
              <transition name="fade">
                <ask-the-pharmacist
                  v-if="askThePharmacist"
                  @cancel="askThePharmacist = false"
                  @done="askThePharmacist = false" /></transition
            ></teleport>
          </div>
          <button
            @click.prevent="$emit('openHelp')"
            class="text-primary mx-2"
            title="Help">
            <IconQuestionCircle class="size-8" />
          </button>
          <router-link
            :to="{ name: 'Account' }"
            class="nav-item nav-item-narrow text-secondary"
            @click.prevent="gotoRoute({ name: 'Account' })"
            ><span
              ><span v-if="$store.state.user"
                >{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}</span
              ></span
            ></router-link
          >
          <router-link
            v-if="hasAnAdminRole"
            :to="{ name: 'Notifications' }"
            class="nav-item nav-item-narrow text-light-grey"
            @click.prevent="gotoRoute({ name: 'Notifications' })">
            <IconBellActive class="size-8" :class="{ 'bell-active': newNotification }" v-if="unreadNotificationCount" />
            <IconBell class="size-8" v-else />
          </router-link>
          <router-link
            :to="{ name: 'Logout' }"
            class="nav-item nav-item-narrow text-light-grey"
            @click.prevent="gotoRoute({ name: 'Logout' })"
            title="Log Out"
            ><IconLogout class="size-8" />
          </router-link>
        </div>
      </nav>

    </div>
  </div>
</template>

<script>
import AskThePharmacist from "@/components/AskThePharmacist";
import OpusLogo from "@/components/OpusLogo.vue";

export default {
  name: "SiteNav",
  components: {
    AskThePharmacist,
    OpusLogo,
  },
  data() {
    return {
      askThePharmacist: false,
      newNotification: false
    };
  },
  computed: {
    hasAnAdminRole() {
      return this.hasSuperAdmin || this.hasGroupAdmin || this.hasSubgroupAdmin || this.hasTrainerRole;
    },
    unreadNotificationCount() {
      return this.$store.state.admin.unreadNotificationCount;
    },
  },
  //watch unreadNotificationCount and toggle bell icon
  watch: {
    unreadNotificationCount(newValue, oldValue) {
      if (newValue > oldValue) {
        this.newNotification = true;
        window.setTimeout(() => {
          this.newNotification = false;
        }, 5000);
      }
    }
  },
  methods: {
    gotoRoute(route) {
      this.$router.push(route);
      this.$store.commit("closeMobileMenu");
    },
    goHome() {
      if (this.hasSuperAdmin) {
        return this.$router.push({ name: "SiteAdmin" });
      }

      if (this.hasGroupAdmin) {
        return this.$router.push({ name: "GroupAdmin" });
      }

      if (this.hasSubgroupAdmin) {
        return this.$router.push({ name: "SubgroupAdmin" });
      }

      if (this.hasTrainerRole) {
        return this.$router.push({ name: "VirtualGroupAdmin" });
      }

      this.$router.push({ name: "MyCourses" });
    }
  }
};
</script>

<style scoped lang="postcss">
.nav-wrapper {
  @apply fixed top-0 left-0 z-10 w-4/5 transition-all duration-300 ease-in-out
     h-screen bg-white border-secondary border-r-4;
  transform: translateX(-100%);
}
.nav-wrapper.active {
  transform: translateX(0);
}
.nav-item {
  @apply flex justify-center items-center;
  @apply px-2 py-2 transition-colors duration-300 text-center text-xs w-full;
}
.nav-item.nav-item-wide {
  @apply w-full text-center;
}
.nav-item.nav-item-narrow {
  @apply px-4 flex-none w-auto;
}
.nav-item.router-link-active,
.nav-item.router-link-active:hover {
  @apply bg-nav text-white;
}
.nav-item:hover {
  @apply bg-nav bg-opacity-60 text-white;
}
.ask-the-pharmacist:hover span span {
  @apply text-white;
}
@screen md {
  .nav-wrapper {
    @apply relative w-full
     h-auto border-r-0;
    transform: translateX(0);
  }
  .nav-item {
    @apply w-auto px-8;
  }
  .nav-item.nav-item-narrow {
    @apply px-4 flex-none w-auto;
  }
}

@screen xl {
  .nav-item {
    @apply w-64 px-8 py-2 text-base;
  }
  .nav-item.nav-item-narrow {
    @apply px-4 flex-none w-auto;
  }
}

.bell-active {
  transform: scale(1);
	animation: pulse 500ms 10 forwards
}

@keyframes pulse {
	0% {
		transform: scale(0.83);
	}

	70% {
		transform: scale(1.17);	
  }

	100% {
		transform: scale(0.83);
	}
}
</style>
