<template>
    <div class="home relative">
        <router-view v-slot="{ Component }" class="px-4 md:px-12">
            <transition name="fade" mode="out-in">
                <component :is="Component" 

                 />
            </transition>
        </router-view>
    </div>
</template>

<script>
export default {
    name: "Home"
};
</script>
