<template>
  <div class="mt-8 mb-6">
    <div class="flex justify-between items-center">
      <div>
        <h1 class="m-0 flex justify-start items-center gap-4"><slot></slot></h1>
      </div>
      <div class="flex justify-end gap-4"><slot name="rightButtons"></slot></div>
    </div>
    <slot name="subTitle"></slot>
  </div>
</template>
