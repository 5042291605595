<template>
    <div class="text-center flex flex-wrap justify-center">
        <h1 class="w-full my-20">Sorry, the page you're looking for could not be found</h1>

        <router-link class="btn btn-primary" :to="{ name: 'Home' }">Return Home</router-link>
    </div>
</template>

<script>
export default {
    name: "PageNotFound"
};
</script>
