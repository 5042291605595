export default {
  computed: {
    hasSuperAdmin() {
      if (!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user.roles.find((r) => r.name === "super_admin");
    },
    hasGroupAdmin() {
      if (!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user.roles.find((r) => r.name === "group_admin");
    },
    hasSubgroupAdmin() {
      if (!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user.roles.find((r) => r.name === "subgroup_admin");
    },
    hasVirtualGroupAdmin() {
      if (!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user.roles.find((r) => r.name === "trainer");
    },
    hasTrainerRole() {
      if (!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user.roles.find((r) => r.name === "trainer");
    },
    hasAnAdminRole() {
      return this.hasSuperAdmin || this.hasGroupAdmin || this.hasSubgroupAdmin || this.hasTrainerRole;
    }
  },
  methods: {
    flashSuccess(message, durationInMs = 7000, type = "banner") {
      this.$store.commit("setFlashMessage", {
        message: message,
        durationInMs: durationInMs,
        type: type,
        status: "success"
      });
    },
    flashWarning(message, durationInMs = 7000, type = "banner") {
      this.$store.commit("setFlashMessage", {
        message: message,
        durationInMs: durationInMs,
        type: type,
        status: "warning"
      });
    },
    flashError(
      message = `Something went wrong, please try again or contact technical support if the problem persists.`,
      durationInMs = 7000,
      type = "banner"
    ) {
      //if message is object check for laravel errors
      if (typeof message === "object" && this.hasLaravelErrors(message)) {
        let errorMessages = [];
        for (let key in message.response.data.errors) {
          errorMessages.push(message.response.data.errors[key][0]);
        }
        message = errorMessages.join(", ");
      }
      this.$store.commit("setFlashMessage", {
        message: message,
        durationInMs: durationInMs,
        type: type,
        status: "error"
      });
    },
    hasLaravelErrors(errors) {
      return errors && errors.response && errors.response.data && errors.response.data.errors;
    },
    isSessionStorageEnabled() {
      try {
        const key = `__storage__test`;
        window.sessionStorage.setItem(key, null);
        window.sessionStorage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
    isLocalStorageEnabled() {
      try {
        const key = `__storage__test`;
        window.localStorage.setItem(key, null);
        window.localStorage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
    //only to be used once we know they're a subgroup manager
    subgroupAdminLevel(subgroupId = null) {
      // if they're not managing any service yet we'll set them at the lowest level
      if (
        !this.$store.state.user ||
        !this.$store.state.user.subgroups_managed ||
        this.$store.state.user.subgroups_managed.length === 0
      ) {
        return "LOW";
      }

      // if no subgroupId e.g. when we need to know what help to show them before they've selected a service
      if (!subgroupId) {
        let level1Count = 0;
        let level2Count = 0;
        let level3Count = 0;
        let level;
        this.$store.state.user.subgroups_managed.forEach((sm) => {
          if (sm.group.group_setting && sm.group.group_setting.default_service_manager_level) {
            level = sm.group.group_setting.default_service_manager_level;
          } else {
            //fail to safe
            level = "LOW";
          }
          if (level === "HIGH") {
            level1Count++;
          }
          if (level === "MED") {
            level2Count++;
          }
          if (level === "LOW") {
            level3Count++;
          }
        });
        if (level1Count && !level2Count && !level3Count) {
          return "HIGH";
        }
        if (level2Count && !level1Count && !level3Count) {
          return "MED";
        }
        return "LOW";
      }

      let idx = this.$store.state.user.subgroups_managed.findIndex((sm) => {
        return sm.id === subgroupId;
      });

      if (idx === -1) {
        return "LOW";
      }

      if (
        this.$store.state.user.subgroups_managed[idx].group &&
        this.$store.state.user.subgroups_managed[idx].group.group_setting
      ) {
        return this.$store.state.user.subgroups_managed[idx].group.group_setting.default_service_manager_level;
      }

      return "LOW";
    }
  }
};
