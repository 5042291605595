export default {
  install: (Vue) => {
    Vue.config.globalProperties.$useOpusFeature = function (feature) {
      let env_flag;
      switch (feature) {
        case 'bookings':
          env_flag = 'VUE_APP_FEATURE_BOOKINGS'
          break;
      
        default:
          env_flag = 'NA'
          break;
      }

      if (process.env[env_flag]==='true') {
        // console.log(`Feature: ${feature} has flag ${env_flag} enabled`);
        return true;
      } else {
        // console.log(`Feature: ${feature} has flag ${env_flag} not enabled`);
        return false;
      }
    }
  }
}