import ApiService from "@/services/ApiService";

export default {
  namespaced: true,
  state: {
    timeline: [],
  },
  mutations: {
    ADD_ENTRY(state, entry) {
      state.timeline.push(entry);
    },
    REMOVE_LAST_ENTRY(state) {
      state.timeline.pop();
    },
    UPDATE_LAST_ENTRY_QUESTION(state, question) {
      state.timeline[state.timeline.length - 1].data.question = question;
    },
  },
  actions: {
    addEntry({ commit }, entry) {
      commit('ADD_ENTRY', entry);
    },
    removeLastEntry({ commit }) {
      commit('REMOVE_LAST_ENTRY');
    },
    askQuestion({ commit, rootState }, question) {
      commit('UPDATE_LAST_ENTRY_QUESTION', question);
      let payload = {
        question,
        name: rootState.user.first_name + " " + rootState.user.last_name,
      };
      ApiService.postAskThePharmacist(payload).catch((error) => {
        console.log(error);
      });
    }
  }
}