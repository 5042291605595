import { createStore } from "vuex";
import ApiService from "@/services/ApiService";
import admin from "@/store/admin/index";
import scorm_1_2 from "@/store/scorm_1_2/index";
import my_learning_zone from "@/store/my_learning_zone/index";
import chat from "@/store/chat/index";

// export 'mutations' as a named export for easier testing
export const mutations = {
  toggleMenuExpanded(state) {
    state.menuExpanded = !state.menuExpanded;
  },
  closeMobileMenu(state) {
    state.menuExpanded = false;
  },
  collapseMenu(state) {
    state.menuExpanded = false;
  },
  
  setUser(state, user) {
    state.user = user;
  },
  setFlashMessage(state, flashMessage) {
    state.flashMessage = flashMessage;
    window.setTimeout(() => {
      state.flashMessage.message = "";
      state.flashMessage.type = "banner";
      state.flashMessage.status = "error";
    }, flashMessage.durationInMs);
  },
  clearFlashMessage(state) {
    state.flashMessage.message = "";
    state.flashMessage.type = "banner";
    state.flashMessage.status = "error";
  },
  setSelectedCourse(state, course) {
    state.admin.selectedCourse = course;
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  setSubgroups(state, subgroups) {
    state.subgroups = subgroups;
  },
  setVirtualGroups(state, virtualGroups) {
    state.virtualGroups = virtualGroups;
  }
};

export default createStore({
  state: {
    menuExpanded: false,
    showContentExitModal: false,
    exitConfirmed: false,
    user: null,
    groups: [],
    subgroups: [],
    virtualGroups: [],
    navigation: {
      sub: "admin",
      subSub: null
    },
    flashMessage: {
      message: "",
      type: "banner",
      status: "error"
    },
    showHeader: true,
    showNav: false,
  },
  getters: {
    getGroupById: (state) => (id) => {
      if (!state.groups || state.groups.length === 0) {
        return null;
      }
      return state.groups.find((g) => parseInt(g.id, 10) === parseInt(id, 10));
    },
    getSubgroupById: (state) => (id) => {
      if (!state.subgroups || state.subgroups.length === 0) {
        return null;
      }
      return state.subgroups.find((g) => parseInt(g.id, 10) === parseInt(id, 10));
    },
    getVirtualGroupById: (state) => (id) => {
      if (!state.virtualGroups || state.virtualGroups.length === 0) {
        return null;
      }
      return state.virtualGroups.find((g) => parseInt(g.id, 10) === parseInt(id, 10));
    }
  },
  mutations,
  actions: {
    getUser(context) {
      return new Promise((resolve, reject) => {
        ApiService.atAuthLevel("member")
          .getUser()
          .then((response) => {
            context.commit("setUser", response.data.user);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout(context) {
      return new Promise((resolve, reject) => {
        ApiService.logout()
          .then(() => {
            context.commit("setUser", null);
            context.commit("admin/setCurrentGroup", null);
            context.commit("admin/setCurrentSubgroup", null);
            context.commit("admin/setCurrentVirtualGroup", null);
            context.commit("admin/setGroupVirtualGroupHealth", null);
            context.commit("admin/setVirtualGroupHealth", null);
            context.commit("admin/setSelectedCourse", null);
            context.commit("admin/setSelectedAbstractCourse", null);
            context.commit("admin/setSelectedAbstractPaperCourse", null);
            context.commit("admin/setSelectedPaperCourse", null);
            context.commit("admin/setCourses", null);
            context.commit("admin/setPaperCourses", null);
            context.commit("admin/setAbstractCourses", null);
            context.commit("admin/setAbstractPaperCourses", null);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  modules: {
    admin,
    scorm_1_2,
    my_learning_zone,
    chat
  }
});
