import auth from "@/middleware/auth.js";
import atOrgLevel from "@/middleware/atOrgLevel.js";
import store from "@/store";

const parseUrlParamToInt = (route, param) => {
    let value = Number.parseInt(route.params[param], 10);
    if (Number.isNaN(value)) {
        value = null;
    }

    return value;
}

export default [{
        path: "",
        name: "SiteAdminGroupSubgroups",
        component: () =>
            import(
                /* webpackChunkName: "siteAdmin" */
                "@/views/site_admin/groups/subgroups/Subgroups.vue"
            ),
        meta: {
            middleware: [auth, atOrgLevel]
        },
        props: route => {
            let groupId = parseUrlParamToInt(route, 'groupId');

            return {
                groupId
            };
        }
    },
    {
        path: ":subgroupId",
        name: "SiteAdminGroupSubgroupLayout",
        component: () =>
            import(
                /* webpackChunkName: "siteAdmin" */
                "@/views/site_admin/groups/subgroups/SubgroupLayout.vue"
            ),
        meta: {
            middleware: auth
        },
        props: route => {
            let groupId = parseUrlParamToInt(route, 'groupId');
            let subgroupId = parseUrlParamToInt(route, 'subgroupId');

            return {
                groupId,
                subgroupId
            };
        },
        children: [{
                path: "edit",
                name: "SiteAdminGroupEditSubgroup",
                component: () =>
                    import( /* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/subgroups/SubgroupEdit.vue"),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "new-user",
                name: "SiteAdminSubgroupNewUser",
                component: () =>
                    import( /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/CreateNewUser.vue"),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "bulk-upload-new-users",
                name: "SiteAdminSubgroupBulkNewUsers",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/BulkUploadNewUsers.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "members",
                name: "SiteAdminGroupSubgroupMembers",
                component: () => import( /* webpackChunkName: "siteAdmin" */ "@/views/common/GroupingMembers.vue"),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let groupingLevel = "subgroup";
                    let apiAuthLevel = "superAdmin";
                    let editUserRoute = {
                        name: "SiteAdminGroupSubgroupEditUser",
                        params: {
                            groupId: groupId,
                            subgroupId: subgroupId,
                            groupingLevel: groupingLevel,
                            userId: null
                        }
                    };
                    let userInfoRoute = {
                        name: "SiteAdminGroupSubgroupMembersInfo",
                        params: {
                            groupId: groupId,
                            subgroupId: subgroupId,
                            groupingLevel: groupingLevel,
                            userId: null
                        }
                    };
                    let licencesRoute = {
                        name: "SiteAdminGroupSubgroupMembersLicences",
                        params: {
                            groupId: groupId,
                            subgroupId: subgroupId,
                            groupingLevel: groupingLevel,
                            userId: null
                        }
                    };
                    return {
                        groupingLevel,
                        groupId,
                        subgroupId,
                        apiAuthLevel,
                        editUserRoute,
                        userInfoRoute,
                        licencesRoute
                    };
                }
            },
            {
                path: "members-info/:userId",
                name: "SiteAdminGroupSubgroupMembersInfo",
                component: () =>
                    import( /* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MembersInfo.vue"),
                meta: {
                    middleware: [auth, atOrgLevel]
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let userId = parseUrlParamToInt(route, 'userId');

                    return {
                        groupId,
                        userId,
                        subgroupId
                    };
                }
            },
            {
                path: "edit-user/:userId",
                name: "SiteAdminGroupSubgroupEditUser",
                component: () => import( /* webpackChunkName: "siteAdmin" */ "@/views/common/EditUser.vue"),
                meta: {
                    middleware: [auth]
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let userId = parseUrlParamToInt(route, 'userId');
                    let groupingLevel = "subgroup";
                    let apiAuthLevel = "superAdmin";
                    return {
                        userId,
                        apiAuthLevel,
                        groupingLevel,
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "membership",
                name: "SiteAdminGroupSubgroupMembership",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/SubgroupMembership.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "licences",
                name: "SiteAdminGroupSubgroupLicences",
                component: () =>
                    import( /* webpackChunkName: "commonAdmin" */ "@/views/common/licences/SubgroupLicences.vue"),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "licences/bulk-issue-from-general-pot/:abstractCourseId/:available/:certificateMonths",
                name: "SiteAdminBulkAllocateGroupSubgroupLicenceToMembersFromPot",
                component: () =>
                    import(
                        /* webpackChunkName: "commonAdmin" */
                        "@/views/common/licences/SubgroupBulkIssueLicences.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let abstractCourseId = parseUrlParamToInt(route, 'abstractCourseId');
                    let available = parseUrlParamToInt(route, 'available');
                    let certificateMonths = parseUrlParamToInt(route, 'certificateMonths');

                    return {
                        groupId,
                        subgroupId,
                        abstractCourseId,
                        available,
                        source: "pot",
                        certificateMonths
                    };
                }
            },
            {
                path: "licences/bulk-issue-from-pool/:abstractCourseId/:available/:certificateMonths",
                name: "SiteAdminBulkAllocateGroupSubgroupLicenceToMembersFromPool",
                component: () =>
                    import(
                        /* webpackChunkName: "commonAdmin" */
                        "@/views/common/licences/SubgroupBulkIssueLicences.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let abstractCourseId = parseUrlParamToInt(route, 'abstractCourseId');
                    let available = parseUrlParamToInt(route, 'available');
                    let certificateMonths = parseUrlParamToInt(route, 'certificateMonths');

                    return {
                        groupId,
                        subgroupId,
                        abstractCourseId,
                        available,
                        source: "pool",
                        certificateMonths
                    };
                }
            },
            {
                path: "new-licence-for-trainer-led-course",
                name: "SiteAdminGroupsSubgroupNewLicenceForTrainerLedCourse",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/common/groups/NewLicenceForTrainerLedCourse.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "virtual-groups",
                name: "SiteAdminGroupSubgroupVirtualGroups",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroups.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                },
                beforeEnter: (to, from, next) => {
                    Promise.all([
                        store.dispatch("admin/primeCurrentSubgroup", {
                            subgroupId: parseInt(to.params.subgroupId, 10)
                        }),
                        store.dispatch("admin/primeCurrentGroup", {
                            groupId: parseInt(to.params.groupId, 10)
                        })
                    ]).then(() => {
                        next();
                    }).catch(() => {
                        next();
                    })
                },
            },
            {
                path: "historic-virtual-groups",
                name: "SiteAdminGroupSubgroupHistoricVirtualGroups",
                component: () =>
                    import(
                        /* webpackChunkName: "commonAdmin" */
                        "@/views/common/groups/subgroups/virtual_groups/SubgroupHistoricVirtualGroups.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let infoRouteName = "SiteAdminGroupSubgroupHistoricVirtualGroupInfo";
                    return {
                        groupId,
                        subgroupId,
                        infoRouteName
                    };
                }
            },

            //Note we're using the same view that's used by group level
            {
                path: "historic-virtual-group/info/:virtualGroupId",
                name: "SiteAdminGroupSubgroupHistoricVirtualGroupInfo",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/common/groups/virtual_groups/HistoricVirtualGroupInfo.vue"
                    ),
                meta: {
                    middleware: [auth]
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');

                    return {
                        groupId,
                        subgroupId,
                        virtualGroupId
                    };
                }
            },
            {
                path: "new-virtual-group/",
                name: "SiteAdminGroupSubgroupNewVirtualGroup",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroupNew.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');

                    return {
                        groupId,
                        subgroupId
                    };
                }
            },
            {
                path: "virtual-groups/:virtualGroupId/edit",
                name: "SiteAdminGroupSubgroupVirtualGroupEdit",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroupEdit.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');

                    return {
                        groupId,
                        subgroupId,
                        virtualGroupId
                    };
                }
            },
            {
                path: "virtual-groups/:virtualGroupId/members",
                name: "SiteAdminGroupSubgroupVirtualGroupMembers",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/common/GroupingMembers.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');
                    let groupingLevel = "virtual group";
                    let apiAuthLevel = "superAdmin";
                    let editUserRoute = {
                        name: "SiteAdminGroupSubgroupEditUser",
                        params: {
                            userId: null
                        }
                    };
                    let userInfoRoute = {
                        name: "SiteAdminGroupSubgroupVirtualGroupMembersInfo",
                        params: {
                            virtualGroupId: virtualGroupId,
                            userId: null
                        }
                    };
                    let licencesRoute = {
                        name: "SiteAdminGroupSubgroupMembersLicences",
                        params: {
                            groupId: groupId,
                            subgroupId: subgroupId,
                            groupingLevel: groupingLevel,
                            userId: null
                        }
                    };
                    return {
                        groupingLevel,
                        groupId,
                        subgroupId,
                        virtualGroupId,
                        apiAuthLevel,
                        editUserRoute,
                        userInfoRoute,
                        licencesRoute
                    };
                }
            },
            {
                path: "virtual-groups/:virtualGroupId/members-info/:userId",
                name: "SiteAdminGroupSubgroupVirtualGroupMembersInfo",
                component: () =>
                    import( /* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/MembersInfo.vue"),
                meta: {
                    middleware: [auth, atOrgLevel]
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let userId = parseUrlParamToInt(route, 'userId');

                    return {
                        groupId,
                        userId,
                        subgroupId
                    };
                }
            },
            {
                path: "virtual-groups/:virtualGroupId/membership",
                name: "SiteAdminGroupSubgroupVirtualGroupMembership",
                component: () =>
                    import(
                        /* webpackChunkName: "siteAdmin" */
                        "@/views/site_admin/groups/subgroups/virtual_groups/SubgroupVirtualGroupMembership.vue"
                    ),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let groupId = parseUrlParamToInt(route, 'groupId');
                    let subgroupId = parseUrlParamToInt(route, 'subgroupId');
                    let virtualGroupId = parseUrlParamToInt(route, 'virtualGroupId');

                    return {
                        groupId,
                        subgroupId,
                        virtualGroupId
                    };
                }
            }
        ]
    }
];
