import Echo from "laravel-echo";
import store from "@/store";
import { useHasSuperAdmin, useHasGroupAdmin, useHasSubgroupAdmin, useHasTrainerRole } from "./helpers";

window.Pusher = require("pusher-js");



const sendNotificationToNotificationApi = (broadcastNotification) => {
  if (window.Notification && Notification.permission == 'granted') {
    // Check broadcastNotification has title, tagName and bodyContent
    if (!broadcastNotification.title || !broadcastNotification.tagName || !broadcastNotification.bodyContent) {
      console.error("Notification is missing title, tagName or bodyContent.");
      return;
    }

    let n = new Notification(broadcastNotification.title, {
      silent: false,
      vibrate: [200, 100, 200],
      tag: broadcastNotification.tagName,
      renotify: true,
      body: broadcastNotification.bodyContent,
      icon: "/img/icons/android-chrome-192x192.png"
    });

    if (broadcastNotification.actionUrl) {
      n.onclick = function () {
        window.location.href = broadcastNotification.actionUrl;
      };
    }
  }
};

export const useAdminsListenToBroadcasts = (userId) => {
  if (useHasSuperAdmin.value || useHasGroupAdmin.value || useHasSubgroupAdmin.value || useHasTrainerRole.value) {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      forceTLS: true
    });

    window.Echo.private("App.Models.User." + userId).notification((broadcastNotification) => {
      store.commit("admin/incrementUnreadNotificationCount");

      /* Firefox and Safari need the requestPermission to be on a short lived event like
      the login click - so we asked it there. */
      if (window.Notification && Notification.permission == 'granted') {
        sendNotificationToNotificationApi(broadcastNotification);
      }
    });
  } else {
    //console.log("You are not authorized to listen to broadcasts.");
  }
};
