<script setup>
const props = defineProps({
    returnRoute: {
        type: Object,
        default: null
    }
})
</script>
<template>
    <button v-if="!props.returnRoute" class="btn btn-cancel" @click.prevent="$router.go(-1)">Cancel</button>
    <button v-else class="btn btn-cancel" @click.prevent="$router.push(props.returnRoute)">Cancel</button>
</template>