import ApiService from "@/services/ApiService";

export default {
  namespaced: true,
  state: {
    filterSettings: {
      courseTitle: null,
      deliverableId: 0,
      status: 0
    },
    courseLaunchData: null,
    exitReturnRoute: null
  },
  getters: {
    filteredCourses: (state) => (courses) => {
      let filteredResults = courses;
      if (state.filterSettings.courseTitle) {
        filteredResults = filteredResults.filter((course) => {
          let theTitle = course.title;
          // if (course.course) {
          //   theTitle = course.course.title;
          // } else if (course.paper_course) {
          //   theTitle = course.paper_course.title;
          // } else {
          //   theTitle = course.external_course.title;
          // }
          return theTitle.toLowerCase().includes(state.filterSettings.courseTitle.toLowerCase());
        });
      }

      if (state.filterSettings.status != 0) {
        filteredResults = filteredResults.filter((course) => {
          if (state.filterSettings.status === "not_started") {
            return course.courseUser.completion_status === null;
          }

          return course.courseUser.completion_status === state.filterSettings.status;
        });
      }

      if (state.filterSettings.deliverableId != 0) {

        filteredResults = filteredResults.filter((course) => {
          let theDeliverableId = course.deliverable_id;
          // if (course.course) {
          //   theDeliverableId = course.course.deliverable_id;
          // } else if (course.paper_course) {
          //   theDeliverableId = course.paper_course.deliverable_id;
          // } else {
          //   theDeliverableId = course.external_course.deliverable_id;
          // }
          return parseInt(theDeliverableId, 10) === parseInt(state.filterSettings.deliverableId, 10)
        });
      }

      return filteredResults;
    }
  },
  mutations: {
    resetFilters(state) {
      state.filterSettings = {
        courseTitle: null,
        deliverableId: 0,
        status: 0
      };
    },
    setCourseLaunchData(state, course) {
      state.courseLaunchData = course;
    },
    setExitReturnRoute(state, route) {
      state.exitReturnRoute = route;
    }
  },
  actions: {
    primeCourseLaunchData({ commit }, { polyCourseId, courseType, groupId }) {
      return new Promise((resolve, reject) => {
        ApiService.getCourseLaunchData(polyCourseId, courseType, groupId)
          .then(({ data }) => {
            commit("setCourseLaunchData", data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  }
};
