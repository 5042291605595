{
    "a group": "an Organisation",
    "group": "Organisation",
    "groups": "Organisations",
    "group's": "Organisation's",
    "Group": "Organisation",
    "Groups": "Organisations",
    "Group's": "Organisation's",
    "paper course": "Face to Face Course",
    "paper courses": "Face to Tace Courses",
    "Paper Course": "Face to Face Course",
    "Paper Courses": "Face to Face Courses",
    "subgroup": "Service",
    "subgroups": "Services",
    "subgroup's": "Service's",
    "Subgroup": "Service",
    "Subgroups": "Services",
    "Subgroup's": "Service's",
    "sessions": "sessions",
    "Sessions": "Sessions",
    "session": "session",
    "Session": "Session",
    "time slots": "time slots",
    "Time Slots": "Time Slots",
    "Time slots": "Time slots",
    "time slot": "time slot",
    "Time Slot": "Time Slot",
    "virtual group": "Pharmacist-led Course",
    "virtual groups": "Pharmacist-led Courses",
    "virtual group's": "Pharmacist-led Course's",
    "Virtual Group": "Pharmacist-led Course",
    "Virtual Groups": "Pharmacist-led Courses",
    "Virtual Group's": "Pharmacist-led Course's",

    "There are no more licences in the General Pot for this course.": "There are no more licences available to the Organisation for this Course.",
    "Loading Available General Pot Licences...": "Loading licences available to the Organisation...",

    "SupportPhoneNumber": "0333 939 0053, option 2"
}
