import auth from "@/middleware/auth.js";
import store from "@/store";
const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminDocuments",
    component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/Documents.vue"),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: "new",
    name: "SiteAdminDocumentsNew",
    component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/DocumentForm.vue"),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: "edit/:documentId",
    name: "SiteAdminDocumentsEdit",
    component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/DocumentForm.vue"),
    meta: {
      middleware: [auth]
    },
    props: (route) => {
      let documentId = parseUrlParamToInt(route, "documentId");

      return {
        documentId
      };
    }
  }
];