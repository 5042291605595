import ApiService from "@/services/ApiService";
import store from "@/store";

// eslint-disable-next-line no-unused-vars
export default function auth({ next, router, to }) {
  //check to see if session is still logged in
  ApiService.checkLoggedInStatus()
    .then((response) => {
      //if their session is still logged in, but the user refreshed the page then their user data will have
      //gone from vuex - so refetch it if it's missing
      if (!store.state.user) {
        store
          .dispatch("getUser")
          .then(() => {
            return next();
          })
          .catch(() => {
            //if it fails here, it'll get picked up when they try to access something that needs roles
          });
      } else if (store.state.user && parseInt(store.state.user.id, 10) !== parseInt(response.data, 10)) {
        //alternatively if they've logged in as a different user in another tab of the same browser the data in vuex
        //will no longer match the current session user, so reload forcing a refresh of vuex in
        //this tab
        window.location.href = "/";
      } else {
        return next();
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 503) {
        //the auth check is failing because the site is in maintenance mode
        if (typeof Storage !== "undefined") {
          let routeToStore = {};
          routeToStore.name = JSON.parse(JSON.stringify(to.name));
          routeToStore.params = JSON.parse(JSON.stringify(to.params));
          routeToStore.query = JSON.parse(JSON.stringify(to.query));

          window.localStorage.setItem("preInteruptRoute", JSON.stringify(routeToStore));
        }
        return router
          .push({
            name: "SiteMaintenance"
          })
          .catch(() => {});
      }

      if (error.toJSON().message === "Network Error") {
        //the auth check is failing because there's no internet connection
        //or it's timed out because the server is down
        if (typeof Storage !== "undefined") {
          let routeToStore = {};
          routeToStore.name = JSON.parse(JSON.stringify(to.name));
          routeToStore.params = JSON.parse(JSON.stringify(to.params));
          routeToStore.query = JSON.parse(JSON.stringify(to.query));

          window.localStorage.setItem("preInteruptRoute", JSON.stringify(routeToStore));
        }
        return router
          .push({
            name: "NetworkConnectivity"
          })
          .catch(() => {});
      }

      if (router.path !== "/login") {
        return router
          .push({
            name: "Login"
          })
          .catch(() => {});
      }
    });
}
