import auth from "../../middleware/auth.js";
const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminBookingSystemDefaultChild",
    redirect: {
      name: "SiteAdminBookingSystemProjects"
    }
  },
  {
    path: "projects",
    name: "SiteAdminBookingSystemProjects",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/projects/Projects.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/new",
    name: "SiteAdminBookingSystemNewProject",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/projects/NewProject.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/edit",
    name: "SiteAdminBookingSystemEditProject",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/projects/EditProject.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let projectId = parseUrlParamToInt(route, "project_id");

      return {
        projectId
      };
    },
  },
  {
    path: "projects/:project_id/project-requests/new",
    name: "SiteAdminBookingSystemNewProjectRequest",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/NewProjectRequest.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/project-requests/:project_request_id/edit",
    name: "SiteAdminBookingSystemEditProjectRequest",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/EditProjectRequest.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/project-requests/:project_request_id/manage",
    name: "SiteAdminBookingSystemManageProjectRequest",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/ManageProjectRequest.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "calendars",
    name: "SiteAdminBookingSystemCalendars",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/calendars/Calendars.vue"),
    meta: {
      middleware: auth
    }
  }
];
