<template>
    <div :class="displayType" v-if="show">
        <div class="flex flex-col justify-center items-center text-center w-full h-full">
            <!-- eslint-disable max-len -->
            <div class="svg-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.38457 35.096192">
                    <g transform="translate(3.394 3.288) scale(.04942)" paint-order="stroke markers fill">
                        <circle class="spinner-symbol-circle" cx="257.723" cy="108.859" r="74.21" fill="#eb690c" />
                        <g class="spinner-symbol">
                            <path
                                class="spinner-symbol-right"
                                d="M433.124 130.113c-2.3.033-4.61.226-6.921.585-15.5 2.41-12.237-.248-101.307 82.623-21.247 19.767-26.354 24.421-42.033 38.958 3.98 3.679 19.828 18.385 19.908 18.455 18.861 16.748 31.838 41.026 31.838 65.642 0 22.76-12.042 46.699-29.621 63.269-5.31 5.004-14.67 13.714-22.209 20.755 53.341 49.575 122.692 113.54 124.823 114.95 9.18 6.076 25.258 8.736 36.856 6.096 8.866-2.018 16.92-6.28 23.735-12.568 17.958-16.566 22.11-39.36 11.209-61.501-3.073-6.242-6.492-10.04-31.587-35.007-15.48-15.4-43.18-43.346-61.554-62.107l-33.406-34.118 62.254-62.882c68.955-69.657 65.935-66.044 69.427-83.166 2.71-13.292-.743-27.432-9.536-39.052-10.234-13.527-25.767-21.161-41.876-20.933z"
                                fill="gray"
                            />
                            <path
                                class="spinner-symbol-left"
                                d="M73.472 541.443c-8.866-2.018-16.914-6.28-23.73-12.568-17.958-16.567-22.114-39.358-11.214-61.5 3.073-6.242 6.494-10.033 31.59-35 15.479-15.4 43.177-43.35 61.55-62.113l33.408-34.112-62.254-62.888c-68.956-69.656-65.934-66.037-69.426-83.159-2.71-13.292.741-27.433 9.534-39.054 11.696-15.459 30.316-23.222 48.803-20.348 15.5 2.41 12.23-.256 101.3 82.615 44.566 41.464 84.24 78.24 88.163 81.724 14.47 12.848 20.914 25.585 20.914 41.335 0 15.711-5.111 26.144-19.412 39.624-42.033 39.62-169.473 157.433-172.37 159.35-9.181 6.076-25.257 8.734-36.856 6.094z"
                                fill="#4e4e4d"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <p class="my-4">{{ message }}</p>
            <slot></slot>
        </div>
    </div>
    <div v-else></div>
</template>

<script>
export default {
    name: "SpinnerMessage",
    props: {
        message: {
            type: String,
            default: "Processing, please wait..."
        },
        displayType: {
            type: String,
            default: "fullscreen"
        },
        delay: {
            type: Number,
            default: 300
        }
    },
    data() {
        return {
            show: false,
            timer: null
        };
    },
    created() {
        if (this.delay === 0) {
            this.show = true;
        } else {
            this.timer = window.setTimeout(() => {
                this.show = true;
            }, this.delay);
        }
    },
    beforeUnmount() {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }
    }
};
</script>

<style lang="postcss">
.svg-wrapper {
    @apply w-24 md:w-32 lg:w-64;
}

.fullscreen {
    @apply fixed inset-0 z-50 p-8 md:p-20 ;

    background-color: rgba(255,255,255,0.8);
}

.centered {
    @apply w-5/6 h-5/6 md:w-3/4 md:h-full lg:h-3/4 mx-auto;
    @apply bg-white flex flex-row justify-center items-center rounded-lg shadow-2xl;
}

.centered-plain {
  @apply w-5/6 h-5/6 md:w-3/4 md:h-full lg:h-3/4 mx-auto bg-white;
}

.component {
    @apply w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mx-auto;
}

.component .svg-wrapper {
    @apply w-full;
}

.spinner-symbol {
    transform-box: fill-box;
    transform-origin: 50%;
    animation-name: rotating;
    animation-duration: 4000ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes rotating {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    33% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
    66% {
        transform: rotate3d(0, 1, 1, 360deg);
    }
}

.spinner-symbol-circle {
    fill-opacity: 0;
    transform-box: fill-box;
    transform-origin: 50%;
    animation-name: fadescale;
    animation-duration: 6000ms;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

@keyframes fadescale {
    0% {
        fill-opacity: 0.62;
        transform: scale(0.62);
    }
    50% {
        fill-opacity: 1;
        transform: scale(1);
    }
    100% {
        fill-opacity: 0.62;
        transform: scale(0.62);
    }
}
</style>
