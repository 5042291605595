import auth from "@/middleware/auth.js";
import groupsChildren from "@/router/group_admin/groupsChildren";
import store from "@/store";

export default [
    {
        path: "",
        name: "GroupAdminDefaultChild",
        redirect: { name: "GroupAdminGroupsLayout" }
    },
    {
        path: "groups",
        name: "GroupAdminGroupsLayout",
        component: () => import(/* webpackChunkName: "groupAdmin" */ "@/views/group_admin/groups/Layout.vue"),
        meta: {
            middleware: auth
        },
        children: [...groupsChildren]
    },
    {
        path: "reports",
        name: "GroupAdminReportsLayout",
        component: () => import(/* webpackChunkName: "groupAdmin" */ "@/views/group_admin/reports/Layout.vue"),
        meta: {
            middleware: auth
        },
        children: [
            {
                name: "GroupAdminReports",
                path: "",
                redirect: { name: "GroupAdminReportsDashboard" }
            },
            {
                path: "dashboard",
                name: "GroupAdminReportsDashboard",
                component: () =>
                    import(/* webpackChunkName: "groupAdmin" */ "@/views/group_admin/reports/Dashboard.vue"),
                meta: {
                    middleware: auth
                },
                props: {
                    level: "group_admin"
                }
            },
            {
                path: "virtual-groups-health/:details",
                name: "GroupAdminReportsVirtualGroupHealth",
                component: () =>
                    import(/* webpackChunkName: "siteAdmin" */ "@/views/group_admin/reports/VirtualGroupHealth.vue"),
                meta: {
                    middleware: auth
                },
                props: route => {
                    let details = route.params.details;

                    return { details };
                }
            },
            {
                path: "certificate-expiries",
                name: "GroupAdminReportsCertificateExpiries",
                component: () =>
                    import(/* webpackChunkName: "groupAdmin" */ "@/views/common/reports/CertificateExpiries.vue"),
                meta: {
                    middleware: auth
                },
                props: {
                    level: "group_admin"
                }
            },
            {
                path: "service-licence-requests",
                name: "GroupAdminReportsSubgroupLicenceRequests",
                component: () =>
                    import(/* webpackChunkName: "groupAdmin" */
                        "@/views/group_admin/reports/SubgroupLicenceRequests.vue"),
                meta: {
                    middleware: auth
                }
            },
            {
                path: "licence-levels",
                name: "GroupAdminReportsLicenceLevels",
                component: () =>
                    import(/* webpackChunkName: "groupAdmin" */ "@/views/group_admin/reports/LowLicenceLevels.vue"),
                meta: {
                    middleware: auth
                }
            },
            {
                path: "results",
                name: "GroupAdminReportsCourseResults",
                component: () =>
                    import(/* webpackChunkName: "groupAdmin" */ "@/views/common/reports/CourseResults.vue"),
                meta: {
                    middleware: auth
                },
                props: { adminLevel: 'groupAdmin'}
            },
            {
                path: "training-records",
                name: "GroupAdminReportsGroupTrainingRecords",
                component: () =>
                    import(
                        /* webpackChunkName: "groupAdmin" */
                        "@/views/common/reports/GroupTrainingRecord.vue"
                    ),
                meta: {
                    middleware: auth
                }
            },
            {
                path: "assessments-due",
                name: "GroupAdminReportsAssessmentsDue",
                component: () =>
                    import(/* webpackChunkName: "groupAdmin" */ "@/views/common/reports/AssessmentsDue.vue"),
                meta: {
                    middleware: auth
                }
            }
        ]
    }
];
