class InteractionPropertyMapper {
    getProperty(fullProperty) {
        let bits;
        bits = fullProperty.split(".");

        return bits[bits.length - 1];
    }
}

export default InteractionPropertyMapper;
