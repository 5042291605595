<template>
  <popup-modal
    ref="popup"
    :large-window="largeWindow">
    <div class="flex flex-col justify-between h-full">
      <h2
        class="text-2xl mb-4"
        v-html="title"
        v-if="title"
        :class="{ 'text-white': largeWindow }"></h2>
      <div
        class="mb-8"
        v-html="message"
        v-if="message"></div>
      <div :class="{ 'flex-1 mb-4': largeWindow }">
        <slot></slot>
      </div>
      <div class="flex flex-row justify-between items-center w-full flex-none">
        <span
          ><button
            v-if="!hideCancelButton"
            class="btn bg-gray-200"
            @click.prevent.stop="_cancel">
            {{ cancelButton }}
          </button></span
        >
        <div>
          <span
            class="btn btn-extra mr-4"
            @click.prevent.stop="_extraButton"
            v-if="extraButtonText">
            {{ extraButtonText }}
        </span>
          <span
            class="btn btn-primary"
            @click.prevent.stop="_confirm"
            >{{ okButton }}</span
          >
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/utilities/PopupModal";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  props: {
    extraButtonText: {
      type: String,
      default: undefined
    }
  },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Cancel", // text for cancel button
    hideCancelButton: false,
    largeWindow: false,


    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      if (opts.hideCancelButton) {
        this.hideCancelButton = opts.hideCancelButton;
      }
      if (opts.largeWindow) {
        this.largeWindow = opts.largeWindow;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _extraButton() {
      this.$emit("extra");
      this.$refs.popup.close();
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    }
  }
};
</script>

<style scoped lang="postcss">
  .btn-extra {
    @apply text-primary bg-white;
  }
  .btn-extra:hover {
    @apply bg-primary text-white;
  }
</style>
```
